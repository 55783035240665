<script setup lang="ts">
import {computed, inject, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import SchedulingOperationWrapper from "@/components/Scheduling/Operations/SchedulingOperationWrapper.vue";
import OperationTags from "@/components/Scheduling/Operations/OperationTags.vue";
import OperationPriorityChip from "@/components/Scheduling/Operations/OperationPriorityChip.vue";
import SchedulingOperationQuantityProgress from "@/components/Scheduling/Operations/SchedulingOperationQuantityProgress.vue";
import SchedulingOperationIsNewTag from "@/components/Scheduling/Operations/SchedulingOperationIsNewTag.vue";
import DelayChip from "@/components/Commons/DelayChip.vue";
import IsSavingSpinner from "@/components/Scheduling/Operations/IsSavingSpinner.vue";
import OperationStatus from "@/components/Scheduling/Operations/OperationStatus.vue";
import OperationStatusItem from "@/components/Scheduling/Operations/OperationStatusItem.vue";
import {useMainStore} from "@/stores/mainStore";
import {
  CSS_OPERATION_CARD_SELECTED_CLASS,
  ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
} from "@/config/constants";
import {OpenOFSidebarFunction, SchedulingOperation} from "@/interfaces";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {storeToRefs} from "pinia";
import {OF_STATUS} from "@oplit/shared-module";
import {TEST_IDS} from "@/config/constants";
import {useStorage} from "@vueuse/core";

interface SchedulingOperationCardProps {
  operation: SchedulingOperation;
  isOpFromGroup?: boolean;
  isLastOp?: boolean;
  doNotRenderLazy?: boolean;
  showStagnation?: boolean;
  hasDoneOps?: boolean;
}

const props = defineProps<SchedulingOperationCardProps>();
const {operation} = toRefs(props);

const emit = defineEmits<{
  (
    e: "change-status",
    payload: {
      update: Partial<SchedulingOperation>;
      operation: SchedulingOperation;
    },
  );
  (
    e: "change-priority",
    payload: {
      update: Partial<SchedulingOperation>;
      operation: SchedulingOperation;
    },
  ): void;
}>();

const openOFSidebar = inject<OpenOFSidebarFunction>("openOFSidebar");

const {variables} = storeToRefs(useMainStore());
const {t} = useI18n();
const schedulingStore = useSchedulingStore();
const {currentlySavingOfIds, currentlyChangingOfIds} =
  storeToRefs(schedulingStore);

const isManualSortingEnabled = useStorage(
  ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
  false,
);

const isOPDone = computed(() => props.operation.op_status === OF_STATUS.DONE);
const hasSubheader = (subheader: string): boolean =>
  !!(subheader || props.operation.link_url);

const changePriority = async (priority: string) => {
  const update = {fast_track: priority};
  emit("change-priority", {update, operation: props.operation});
};
</script>

<template>
  <SchedulingOperationWrapper
    :operation="operation"
    :is-op-from-group="isOpFromGroup"
    :do-not-render-lazy="doNotRenderLazy"
    :show-stagnation="showStagnation"
    :has-done-ops="hasDoneOps"
  >
    <template
      v-slot:default="{
        colorCategoryClass,
        compact,
        header,
        isSyntheticView,
        isOngoingLimit,
        isSelected,
        messages,
        operationDelay,
        operationQuantity,
        operationStagnation,
        selectCard,
        showOperation,
        subheader,
      }"
    >
      <div
        :class="[
          {
            [CSS_OPERATION_CARD_SELECTED_CLASS]: isSelected,
            'scheduling-operation-card__list-item': isOpFromGroup,
            'scheduling-operation-card__last-item': isLastOp,
            'scheduling-operation-card__op-status-done': isOPDone,
          },
        ]"
        class="scheduling-operation-card__content shadow-neutral-sm"
        data-testid="scheduling-operation-card-content"
        @click="selectCard"
      >
        <div
          class="d-flex flex-column pa-1"
          :class="[{'pl-2': !!colorCategoryClass}, colorCategoryClass]"
        >
          <div class="scheduling-operation-card__header__container">
            <SchedulingOperationIsNewTag :operation="operation" />

            <OperationPriorityChip
              v-if="!!operation.fast_track"
              :op="operation"
              @change-priority="changePriority"
              edit-only
            />

            <strong
              v-tooltip="header"
              class="scheduling-operation-card__header text-ellipsis text-16 semi-bold"
              data-testid="scheduling-operation-card-header"
            >
              {{ header }}
            </strong>

            <span v-if="operation.is_smoothed && operation.smoothing_position">
              {{ `(${operation.smoothing_position})` }}
            </span>

            <IsSavingSpinner
              :is-changing="currentlyChangingOfIds[operation.of_id]"
              :is-saving="currentlySavingOfIds[operation.of_id]"
              :is-selected="isSelected"
              :has-error="currentlySavingOfIds[operation.of_id] === false"
            />

            <div
              v-if="isManualSortingEnabled && operation.wip_order != null"
              class="fd-flex-center"
            >
              <DevHelper>{{ `(${operation.wip_order})` }}</DevHelper>

              <OplitIcon
                :fill="variables.newPrimaryRegular"
                name="hand-swipe-left"
                size="20px"
              />
            </div>

            <DelayChip :delay="operationDelay" />
          </div>

          <div class="d-flex flex-column gap-1 pa-1 pb-0">
            <div
              v-if="hasSubheader(subheader)"
              class="scheduling-operation-card__subheader text-16"
            >
              <span
                v-if="subheader"
                data-testid="scheduling-operation-card-subheader"
              >
                {{ subheader }}
              </span>

              <a
                v-if="operation.link_url"
                :href="operation.link_url"
                target="_blank"
              >
                <vue-feather tag="div" type="external-link" size="12" />
              </a>
            </div>

            <div class="scheduling-operation-card__info">
              <OplitIcon
                v-if="operation.batch_id"
                v-tooltip="operation.batch_name"
                name="squares"
                size="20px"
                :stroke="variables.newPrimaryDark2"
                class="mr-1"
              />

              <span
                class="scheduling-operation-card__info__quantity text-14"
                data-testid="scheduling-operation-card-quantity"
              >
                {{ operationQuantity.txt }}
              </span>

              <span
                v-if="operation.duration"
                data-testid="scheduling-operation-card-duration-unit"
              >
                {{ `(h)` }}
              </span>
            </div>

            <div
              v-if="isOngoingLimit"
              v-tooltip.start="t('operation.max_wip')"
              class="scheduling-operation-card__is-limit"
            />

            <div
              v-if="showStagnation"
              v-tooltip="t('scheduling.stagnation')"
              class="scheduling-operation-card__stagnation"
              data-testid="scheduling-operation-card-stagnation"
            >
              <vue-feather type="watch" size="14" />

              {{ operationStagnation }}
            </div>

            <div
              class="scheduling-operation-card__chips"
              data-testid="scheduling-operation-card-chips"
            >
              <OperationTags
                :operation="operation"
                :is-synthetic-view="isSyntheticView"
                hide-status
              />

              <vue-feather
                v-if="messages.length && !compact"
                :stroke="variables.newDisableText"
                type="message-circle"
                size="16"
                class="has-messages position-relative cursor-pointer"
                @click.stop="openOFSidebar(operation, {defaultSelectedTab: 1})"
              />
            </div>
          </div>
        </div>

        <SchedulingOperationQuantityProgress :operation="operation" />

        <div class="fd-flex-center">
          <OperationStatus
            :operation="operation"
            @change-status="
              (update) => emit('change-status', {update, operation})
            "
          >
            <template #activator="{props, status, append}">
              <OperationStatusItem
                v-bind="props"
                :status="status"
                :append="append"
              />
            </template>
          </OperationStatus>

          <vue-feather
            v-if="!compact"
            class="pa-2 bg-grey-25"
            type="maximize-2"
            size="24px"
            :stroke="variables['grey-600']"
            :data-testid="TEST_IDS.SCHEDULING_OPERATION_CARD__MAXIMIZE_ICON"
            @click.stop="showOperation"
          />
        </div>
      </div>
    </template>
  </SchedulingOperationWrapper>
</template>

<style scoped lang="scss">
@import "@/scss/components/operation-card";

.scheduling-operation-card__content {
  border: none !important;

  &:not(.selected-card):hover {
    background: rgb(var(--v-theme-grey-25));
  }
}
</style>
