<template>
  <Modal
    ref="explainModal"
    :title="$t('scheduling.my_last_rescheduling')"
    :container-class="[
      'explain-modal',
      {'has-right-sidebar': isDevEnv && selectedLog},
    ]"
    content-wrapper-class="pt-0"
    :is-loading="loading"
    :z-index="1000"
    is-dense
    hide-borders
    hide-scrollbar
    hide-footer
    @close-modal="toggleModal"
    @confirm-modal="toggleModal"
  >
    <div>
      <div>
        <div class="sticky-block">
          <div>
            <span>{{ $t("global.done_in") }} </span>
            <span class="fbody-1 semi-bold mx-2">
              {{ reschedulingDate }}
            </span>
            <span v-if="lastReschedulingCall?.user?.name">
              {{ $t("global.by") }}
              <span class="fbody-1 semi-bold ml-2">
                {{ lastReschedulingCall.user.name }}
              </span>
            </span>
            <span
              v-if="!isArchivedSimulation(selectedSimulation)"
              class="text-newPinkRegular underlined cursor-pointer pl-2"
              @click="() => $emit('cancel-open')"
            >
              {{ $t("scheduling.one_error") }} ?
              <span class="fbody-1 semi-bold">
                {{ $t("scheduling.cancel_rescheduling") }}
              </span>
            </span>
          </div>

          <div v-if="isDevEnv && reschedulingCalls.length">
            <FDropdown
              :model-value="reschedulingCall"
              :items="reschedulingCalls"
              item-value="rescheduling_id"
              item-text="text"
              outlined
              return-object
              @change="onReschedulingCallSelect"
            />
            <dev-helper mini>({{ reschedulingCall.id }})</dev-helper>
          </div>

          <v-divider class="my-4 bg-newHover" />

          <div v-if="!hasDownloadData" class="d-flex align-end filters gap-8">
            <div class="flex-1">
              <span class="fbody-1 semi-bold">
                {{ $t("general.free_search") }}
              </span>
              <FTextField
                :model-value="filters.text"
                icon="search"
                clearable
                :placeholder="$t('scheduling.free_search_placeholder')"
                @update:model-value="(v) => updateFilters('text', v)"
              />
            </div>

            <div class="flex-1">
              <span class="fbody-1 semi-bold">
                {{ $t("Simulation.pc") }}
              </span>
              <SectorsSelector
                class="mb-0"
                only-dropdown
                can_make_change
                can-select-parent
                full-size
                :selected-machine-prop="computedMachineCenter"
                @update-sector="
                  (v) => updateFilters('secteur_id', v?.secteur_id)
                "
              />
            </div>

            <div class="flex-1 d-flex flex-column">
              <span class="fbody-1 semi-bold">
                {{ $t("global.date") }}
              </span>
              <FDatePicker
                icon="calendar"
                :placeholder="$t('Simulation.enter_a_date')"
                :date-value="computeDate(filters.day_date)"
                :field-value="filters.day_date"
                :handle-change="(v) => updateFilters('day_date', v)"
                :displayed-tabs="['day']"
              />
            </div>

            <div v-if="isDevEnv && isAnyFilterActive" class="flex-1">
              <FDropdown
                v-model="adjacentDisplayOption"
                :items="adjacentDisplayOptions"
                btn-class="cursor-pointer w-100"
                return-object
                input
                hide-chevron
                outlined
                placeholder="Lignes adjacentes"
                item-text="text"
                item-value="value"
              />
            </div>

            <div v-if="isDevEnv" class="flex-1">
              <span class="fbody-1 semi-bold"> op_id </span>
              <FTextField
                :model-value="filters.op_id"
                icon="search"
                clearable
                placeholder="op_id"
                @update:model-value="(v) => updateFilters('op_id', v)"
              />
            </div>

            <div
              v-if="filteredRows.length"
              id="export-container"
              class="d-flex"
            >
              <v-tooltip location="top" attach="#export-container">
                <template v-slot:activator="{props}">
                  <div v-bind="props">
                    <vue-feather
                      class="cursor-pointer pb-1"
                      type="download"
                      size="22"
                      @click="exportData"
                    />
                  </div>
                </template>

                {{ $t("global.export") }}
              </v-tooltip>
            </div>
          </div>
        </div>

        <div
          v-if="!hasDownloadData"
          class="overflow-auto keep-scrollbar"
          :style="getAvailableMaxHeight"
          @scroll.self="onScrollScrollableArea"
        >
          <div class="header-block">
            <div id="manage-details" class="d-flex flex-column gap-4">
              <div class="fbody-2 text-newDisableText">
                {{ $t("scheduling.explain_rescheduling") }}
              </div>
              <div>
                <div class="fd-flex-center gap-4 flex-wrap">
                  <span class="fbody-1">
                    {{ $t("scheduling.sectors_concerned") }} :
                  </span>
                  <div
                    v-for="(sector, idx) in computedConcernedMachineCenter"
                    :key="`concerned-machine-center${idx}`"
                  >
                    <v-tooltip location="top">
                      <template v-slot:activator="{props}">
                        <div v-bind="props">
                          <FChip
                            class="mr-2"
                            color="newPrimaryRegular"
                            text-color="newLayerBackground"
                          >
                            {{ sector }}
                          </FChip>
                        </div>
                      </template>
                      <span>{{ sector }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
              <div>
                {{ computedStartDateDetails }}
              </div>
              <div v-if="hasFrozenOFs">
                {{ computedFrozenOfPeriodDetails }}
              </div>
              <div
                v-if="parametersRows.length"
                class="bg-newSubBackground pa-6 border-eadius-8 d-flex flex-column gap-1"
              >
                <div
                  v-for="(parameterRow, index) in parametersRows"
                  :key="parameterRow.text"
                  :class="{'mt-4': index > 0}"
                >
                  <pre>{{ parameterRow.text }}</pre>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex mt-6 gap-8 keep-scrollbar">
            <div class="logs-wrapper">
              <div class="d-flex flex-column gap-12 flex-1">
                <div class="fd-flex-center gap-8">
                  <h4 class="bold inline">{{ $t("global.Logs") }} :</h4>
                  <span class="fbody-1" v-if="rows.length">
                    {{
                      `${filteredRows.length} / ${rows.length} ${$t(
                        "global.lines",
                      )}`
                    }}
                  </span>
                </div>

                <div class="logs">
                  <v-skeleton-loader v-if="loading" boilerplate type="table" />

                  <div
                    v-else-if="filteredRows.length"
                    v-for="(row, idx) in filteredRows.slice(0, displayedLogs)"
                    :key="row.text"
                    :class="[
                      'log-row',
                      {
                        'exact-match': row.exactMatch,
                        'cursor-pointer': isDevEnv,
                      },
                    ]"
                    @click="() => (selectedLog = row)"
                  >
                    <span v-if="isDevEnv && row.sort_version">
                      ({{ row.sort_version }})
                    </span>
                    <div class="fbody-1">
                      <span
                        class="fbody-1 semi-bold d-inline-block"
                        :style="getLogRowStyle"
                      >
                        {{
                          `${getDisplayedRowIndex(row, idx)} / ${rows.length}`
                        }}
                      </span>
                      {{ row.text }}
                    </div>
                  </div>

                  <div v-else-if="rows.length">
                    {{ $t("ExplainModal.replanif_no_info_filters") }}
                  </div>
                  <div v-else>
                    {{ $t("ExplainModal.replanif_no_info") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="fd-flex-center flex-column">
          <div class="fbody-1 text-center">
            <div>
              {{ $t("scheduling.too_many_logs") }}
            </div>
            <div>
              {{ $t("scheduling.click_to_download_logs") }}
            </div>
          </div>
          <FButton class="mt-4" icon="download" medium @click="downloadCsv">
            {{ $t("scheduling.download_logs") }}
          </FButton>
        </div>
      </div>
    </div>

    <template v-if="isDevEnv && selectedLog" #right-sidebar>
      <u class="cursor-pointer" @click="() => (selectedLog = null)">Fermer</u>
      <pre>{{ selectedLog }}</pre>
    </template>
  </Modal>
</template>

<script lang="ts">
import {defineComponent, ref, PropType, computed, unref} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import moment from "moment";

import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {
  FTextField,
  Modal,
  FDropdown,
  FChip,
  FButton,
} from "@/components/Global";
import FDatePicker from "@/components/Global/FDatePicker.vue";
import SectorsSelector from "@/components/Scheduling/Navbar/NavbarComponents/SectorsSelector.vue";
import {
  ParametersSchedulingRuleAIDataBySector,
  ReschedulingLogs,
  parseReschedulingLogs,
  periodeToText,
} from "@oplit/shared-module";
import {
  GenericObject,
  HTMLElementStyleObject,
  MachineCenterWithTags,
  OpenSnackbarFunction,
  Poste,
  ReschedulingCall,
} from "@/interfaces";
import useExportGenerateCSV from "@/composables/useExportGenerateCSV";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useMainStore} from "@/stores/mainStore";
import {useI18n} from "vue-i18n";
import {isArchivedSimulation} from "@/tscript/utils/simulation";
import {clickOnLink} from "@/tscript/utils/exportUtils";
import {inject} from "vue";

export default defineComponent({
  name: "explain-modal",
  components: {
    SectorsSelector,
    FTextField,
    FDatePicker,
    Modal,
    FDropdown,
    FChip,
    FButton,
  },
  props: {
    toggleModal: {type: Function as PropType<() => void>, required: true},
    reschedulingDate: {type: String, default: ""},
    lastReschedulingCall: {type: Object, default: () => ({})},
  },
  emits: ["cancel-open"],
  setup() {
    const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");

    const {generateCSV} = useExportGenerateCSV();
    const {t, messages, locale} = useI18n({useScope: "global"});
    const {
      selectedSimulation,
      schedulingMachineCenters,
      schedulingMachineCentersAndTags,
    } = storeToRefs(useSchedulingStore());
    const mainStore = useMainStore();
    const {userData, isDevEnv, stations, variables, apiClient, users} =
      storeToRefs(mainStore);

    const reschedulingCall = ref<{
      id: string;
      ai_data_by_sector: ParametersSchedulingRuleAIDataBySector[];
      rescheduling_id: string;
      /**
       * since OPL-7153 we can set different dates to rescheduled sectors
       * we store this mapping in `sectors_by_date` rather than the old `start_date`/`sectors` keys
       */
      start_date?: string;
      sectors_by_date?: {[date: string]: string[]};
      pace_on_extra_field?: string;
      freeze_from?: string;
      freeze_to?: string;
      logs_location?: string;
    }>();
    const downloadData = ref<{download_url?: string; filename?: string}>({});

    const computedStartDateDetails = computed<string>(() => {
      const {start_date, sectors_by_date} = unref(reschedulingCall) || {};

      const dates = sectors_by_date
        ? Object.keys(sectors_by_date).sort()
        : [start_date];

      const [startDate, endDate] = [
        ...new Set(
          [dates[0], dates.at(-1)].map((date) =>
            moment(date).format("DD-MM-YYYY"),
          ),
        ),
      ];

      const suffix = endDate
        ? t("ExplainModal.between_dates", {startDate, endDate})
        : startDate;

      return `${t("scheduling.rescheduling_start_date")} ${suffix}`;
    });
    const hasFrozenOFs = computed<boolean>(() => {
      const {freeze_from, freeze_to} = unref(reschedulingCall) || {};
      return !!freeze_from && !!freeze_to;
    });
    const computedFrozenOfPeriodDetails = computed<string>(() => {
      const {freeze_from, freeze_to} = unref(reschedulingCall) || {};
      const freezeFrom = moment(freeze_from).format("Do");
      const freezeTo = moment(freeze_to).format("ll");
      return `${t("scheduling.frozen_ofs_on_period")} ${freezeFrom} ${t(
        "Commons.to",
      )} ${freezeTo}`;
    });
    const hasDownloadData = computed<boolean>(
      () => !!downloadData.value.download_url && !!downloadData.value.filename,
    );

    function downloadCsv() {
      const {download_url, filename} = downloadData.value;
      if (!hasDownloadData.value) return;
      clickOnLink(download_url, filename);
    }

    async function fetchReschedulingLogsFromPg() {
      const {
        rescheduling_id,
        ai_data_by_sector = [],
        pace_on_extra_field,
      } = reschedulingCall.value || {};

      //prepare the i18n keys the route will need
      const i18nObj = {};
      //recursively inject every key in the object
      function recursivelyInjectObjectValues(
        obj: Record<string, any> | string,
        parentPath: string,
      ) {
        if (typeof obj === "object") {
          Object.keys(obj).forEach((key: string) => {
            const subPath = `${parentPath}.${key}`;
            const value = obj[key];
            recursivelyInjectObjectValues(value, subPath);
          });
        } else if (typeof obj === "function")
          _.set(i18nObj, parentPath, (obj as any).source);
        else _.set(i18nObj, parentPath, obj);
      }
      [
        "Parametres.schedule_rules",
        "ParametersSchedulingRulesConstraints",
        "Simulation.in_unit_lowercase",
        "scheduling",
        "global.by",
      ].forEach((path: string) => {
        let valueAtPath = _.get(
          messages.value[(locale.value as string) || "fr"],
          path,
        );
        recursivelyInjectObjectValues(valueAtPath, path);
      });

      //call backend to get the logs
      const [errFromPostgres, reschedulingLogsFromPostgres] =
        await apiClient.value.getReschedulingLogs({
          rescheduling_id,
          ai_data_by_sector,
          pace_on_extra_field,
          stations: stations.value.map(({id, parent_text}) => ({
            id,
            parent_text,
          })),
          i18n_keys: i18nObj,
        });
      if (errFromPostgres) {
        openSnackbar(null, null, errFromPostgres);
        return [];
      }
      const {raw_logs, download_url, filename} = reschedulingLogsFromPostgres;
      if (filename && download_url) {
        downloadData.value = {download_url, filename};
        return;
      } else return raw_logs;
    }

    return {
      rows: ref<GenericObject[]>([]),
      filters: ref<{
        secteur_id: string;
        day_date: string;
        text: string;
        op_id: string;
      }>({
        secteur_id: null,
        day_date: null,
        text: "",
        op_id: "",
      }),
      loading: ref<boolean>(true),
      adjacentDisplayOptions: ref<{text?: string; value?: number}[]>([
        {text: "Aucune", value: 0},
        {text: "1 ligne adjacente", value: 1},
        {text: "5 lignes adjacentes", value: 5},
        {text: "10 lignes adjacentes", value: 10},
      ]),
      adjacentDisplayOption: ref<{text?: string; value?: number}>({}),
      selectedLog: ref<GenericObject>(null),
      reschedulingCall,
      reschedulingCalls: ref<GenericObject[]>([]),
      // sets the height of the scrollable part at the bottom (required for scrolling)
      maxHeight: ref<string>("200px"),
      // number of logs displayed ; for performance we do not render the full list directly
      displayedLogs: ref<number>(250),
      parametersRows: ref<GenericObject[]>([]),
      downloadData,
      hasDownloadData,
      generateCSV,
      selectedSimulation,
      schedulingMachineCenters,
      userData,
      isDevEnv,
      stations,
      variables,
      hasFrozenOFs,
      computedStartDateDetails,
      computedFrozenOfPeriodDetails,
      apiClient,
      users,
      isArchivedSimulation,
      downloadCsv,
      fetchReschedulingLogsFromPg,
      schedulingMachineCentersAndTags,
    };
  },
  computed: {
    computedMachineCenter() {
      const {
        schedulingMachineCenters,
        filters: {secteur_id},
      } = this;
      if (!secteur_id) return;
      return schedulingMachineCenters.find(
        (s: any) => s.secteur_id === secteur_id,
      );
    },
    computedConcernedMachineCenter(): Poste[] {
      const {reschedulingCall, stations, schedulingMachineCentersAndTags} =
        this;
      const {sectors: oldFormatSectors, sectors_by_date} = reschedulingCall;

      const sectors = sectors_by_date
        ? Object.values(sectors_by_date).flat()
        : oldFormatSectors;

      if (!sectors?.length) return [];
      return sectors.map(
        (mc: string) =>
          stations.find((p: Poste) => p.id === mc)?.parent_text ||
          schedulingMachineCentersAndTags.find(
            (mcwt: MachineCenterWithTags) => mcwt.secteur_id === mc,
          )?.secteur_name ||
          "",
      );
    },
    isAnyFilterActive(): boolean {
      const {filters} = this;
      const activeFilters = Object.values(filters).filter(Boolean);
      return !!activeFilters.length;
    },
    filteredRows() {
      const {
        rows,
        filters,
        adjacentDisplayOption: {value: adjacentRows},
        isAnyFilterActive,
      } = this;
      if (!isAnyFilterActive) return rows;
      const {secteur_id, day_date, text, op_id} = filters;
      const filteredArr = rows
        .map((row: any, idx: number) => {
          if (secteur_id && row.secteur_id !== secteur_id) return;
          if (
            text &&
            !(row.text + "").includes(text) &&
            !(row.sort_version + "").includes(text)
          )
            return;
          if (op_id && row.op_id !== op_id) return;
          if (
            day_date &&
            !(row.text + "").includes(moment(day_date).format("DD/MM/YYYY"))
            // [
            //   row.day_date,
            //   row.new_date,
            //   row.old_date,
            //   row.initial_date,
            // ].includes(day_date)
          )
            return;
          if (adjacentRows) return idx;
          return row;
        })
        .filter((x: any) => x !== undefined);
      if (!adjacentRows) return filteredArr;
      const filteredWAdjacents = rows
        .map((x: any, idx: number) => {
          const isInBoundaries = filteredArr.some(
            (i: number) => idx >= i - adjacentRows && idx <= i + adjacentRows,
          );
          if (!isInBoundaries) return;
          const isExactMatch = filteredArr.includes(idx);
          if (isExactMatch) return {...x, exactMatch: true};
          else return x;
        })
        .filter(Boolean);
      return filteredWAdjacents;
    },
    getLogRowStyle(): HTMLElementStyleObject {
      if (!this.rows.length) return;
      // e.g. if we have 3500 lines, the longest we display is "3500 / 3500"
      return {
        width: `${`${this.rows.length}`.length * 2 + 3}ch`,
      };
    },
    getAvailableMaxHeight(): HTMLElementStyleObject {
      return {
        "max-height": `${this.maxHeight}px`,
      };
    },
  },
  watch: {
    userData: {
      immediate: true,
      handler: function (val: {id: string}) {
        if (!val?.id) return;
        this.loadReschedulingCalls();
      },
    },
    lastReschedulingCall: {
      immediate: true,
      handler: function (val: ReschedulingCall) {
        this.setReschedulingCall(val);
      },
    },
    reschedulingCall: {
      immediate: true,
      handler: function (val: ReschedulingCall, oldVal: ReschedulingCall) {
        if (!val?.rescheduling_id) return;
        if (val.rescheduling_id === oldVal?.rescheduling_id) return;
        this.loadAiLogs();
      },
    },
    loading(isLoading: boolean) {
      if (!isLoading) this.setScrollableAreaHeight();
    },
  },
  methods: {
    setScrollableAreaHeight(): void {
      const modalContainer: HTMLElement =
        this.$el?.querySelector(".f-modal-container");
      if (!modalContainer) return;
      // we need to do the logic when the content is updated within the DOM
      this.$nextTick(() => {
        const stickyBlockHeight =
          this.$el.querySelector(".sticky-block")?.offsetHeight || 265; // approximative height of the sticky-block
        // the select renders after this nexTick, therefore its height is not accounted in the offsetHeight above
        const reschedulingListSelectHeight = this.isDevEnv ? 40 : 0;
        // const stickyBlockHeight = 265 + 56;
        // height of the modal title - static 30px line-height + 24px top padding
        const headerHeight = 54;
        // bottom padding of the modal
        const bottomPadding = 24;

        this.maxHeight =
          modalContainer.offsetHeight -
          (stickyBlockHeight +
            reschedulingListSelectHeight +
            headerHeight +
            bottomPadding);
      });
    },
    async fetchReschedulingLogs(): Promise<ReschedulingLogs[]> {
      const {
        reschedulingCall,
        userData: {client_id},
      } = this;
      const {rescheduling_id, logs_location} = reschedulingCall || {};

      if (logs_location === "pg") return this.fetchReschedulingLogsFromPg();

      return dbHelper.getAllDataFromCollectionWithAll("scheduling_ai_logs", {
        where: [
          {
            field: "client_id",
            value: client_id,
            compare: "==",
          },
          {
            field: "rescheduling_id",
            value: rescheduling_id,
            compare: "==",
          },
        ],
        orderBy: [{field: "insert_order", direction: "asc"}],
      });
    },
    async loadAiLogs() {
      const {reschedulingCall, stations} = this;
      const {rescheduling_id} = reschedulingCall || {};
      this.downloadData = {};
      if (!rescheduling_id) {
        this.rows = [{text: this.$t("global.error_occurred")}];
        return;
      }
      this.loading = true;

      // get all logs
      const reschedulingLogs = await this.fetchReschedulingLogs();

      if (!reschedulingLogs?.length) {
        this.rows = [
          {
            text: `Aucune explication n'est disponible sur cette replanification`,
          },
        ];
        this.loading = false;
        return;
      }

      const {
        ai_data_by_sector = [],
        pace_on_extra_field,
      }: {
        ai_data_by_sector: ParametersSchedulingRuleAIDataBySector[];
        pace_on_extra_field: string;
      } = reschedulingCall;

      const {rows, parametersRows} = parseReschedulingLogs(
        {
          ai_data_by_sector,
          reschedulingLogs,
          stations,
          pace_on_extra_field,
        },
        this.$t,
      );

      this.parametersRows = parametersRows;
      this.rows = rows;
      this.loading = false;
    },
    async loadReschedulingCalls() {
      const {selectedSimulation, userData, isDevEnv, users} = this;
      const {id: simulation_id} = selectedSimulation || {};
      const {client_id} = userData || {};
      if (!isDevEnv || !simulation_id || !client_id) return;
      const reschedulingCalls = await dbHelper.getAllDataFromCollectionWithAll(
        "scheduling_ai_calls",
        {
          where: [
            {
              field: "client_id",
              value: client_id,
              compare: "==",
            },
            {
              field: "simulation_id",
              value: simulation_id,
              compare: "==",
            },
          ],
          orderBy: [{field: "rescheduling_id", direction: "desc"}],
        },
      );
      const mappedData = (reschedulingCalls || []).map((x: any) => ({
        ...x,
        text: `${x.rescheduling_id} par ${
          users.find(({id}) => id === x.user_id)?.name ?? x.user_id
        }`,
      }));
      this.reschedulingCalls = mappedData;
    },
    updateFilters(field: string, value: any) {
      this.filters[field] = value;
    },
    computeDate(date: string) {
      if (!date) return "Date";
      return periodeToText(date);
    },
    exportData() {
      const {
        reschedulingCall: {rescheduling_id},
        rows,
      } = this;
      if (!rows?.length) return;
      this.generateCSV(rows, `logs_${rescheduling_id}`, {
        pushKeysToFirstRow: true,
      });
    },
    getDisplayedRowIndex(row: any, idx: number): number {
      if (Object.keys(this.filters).every((key: string) => !this.filters[key]))
        return idx + 1;
      return (
        this.rows.findIndex(
          (r: any) => r.id === row.id && r.text === row.text,
        ) + 1
      );
    },
    onReschedulingCallSelect(call: ReschedulingCall): void {
      const {reschedulingCall} = this;
      if (reschedulingCall?.rescheduling_id === call.rescheduling_id) return;
      this.setReschedulingCall(call);
    },
    setReschedulingCall(newReschedulingCall: ReschedulingCall): void {
      const text = [
        newReschedulingCall.rescheduling_id,
        newReschedulingCall.user?.name,
      ]
        .filter(Boolean)
        .join(` ${this.$t("global.by")} `);

      this.reschedulingCall = {
        text,
        ...newReschedulingCall,
      };
    },
    // pushes remaining children upon scrolling to the bottom of the current list
    onScrollScrollableArea: _.debounce(function (this: any, event: any) {
      if (this.displayedLogs === this.totalRowsLength) return;
      const logsRows = this.$el.querySelectorAll(".log-row");
      const log = [...logsRows].pop();
      const topDifference =
        this.$el.querySelector("#manage-details")?.offsetHeight || 200;
      // value in px to push before reaching end of list
      const delta = 750;
      if (
        log &&
        log.offsetTop <= event.target.scrollTop + topDifference + delta
      )
        this.displayedLogs = this.totalRowsLength;
    }, 250),
  },
});
</script>

<style lang="scss">
.explain-modal {
  --local-max-width: 864px;
  max-width: var(--local-max-width);
  background: rgb(var(--v-theme-newLayerBackground));

  &.has-right-sidebar {
    max-width: calc(var(--local-max-width) + 300px);
  }
}
</style>

<style scoped lang="scss">
.log-row {
  white-space: pre-wrap;
  border-radius: 8px;
  padding: 8px;
  margin-left: -8px;
  margin-top: -8px;
  &.exact-match {
    background-color: rgb(var(--v-theme-newSubBackground));
  }
  &:hover {
    background-color: rgb(var(--v-theme-newHover));
  }
}
.sticky-block {
  position: sticky;
  top: -24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: -24px;
  /* value in px is the equivalent of current pa-5 applied on container */
  border-top: 24px solid rgb(var(--v-theme-newLayerBackground));
  border-bottom: 32px solid rgb(var(--v-theme-newLayerBackground));
  background-color: rgb(var(--v-theme-newLayerBackground));
  z-index: 1;
}
.header-block {
  background-color: rgb(var(--v-theme-newLayerBackground));
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.logs-wrapper {
  display: flex;
  gap: 8px;
  flex: 1;

  & .logs {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
.log-detail {
  flex: 0 0 300px;
  position: sticky;
  overflow: auto;
  max-height: 300px;
}
.inline {
  display: inline-block;
}
.underlined {
  text-decoration-line: underline;
}
.border-radius-8 {
  border-radius: 8px;
}

:deep(.mx-datepicker .f-text-field__wrapper) {
  flex: 1;
}
</style>
