<script setup lang="ts">
import {computed, defineProps} from "vue";
import {storeToRefs} from "pinia";
import {useI18n} from "vue-i18n";
import numeral from "numeral";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {Sector} from "@/interfaces";

interface EnCoursColumnSectorWIPRateProps {
  sector: Sector;
  ongoing: number;
  operationsCount: number;
  minWip: number | null;
  maxWip: number | null;
}

const props = defineProps<EnCoursColumnSectorWIPRateProps>();

const {t} = useI18n();

const {selectedConwipGroup, conwipTicketsGaugeBySectorID} = storeToRefs(
  useSchedulingStore(),
);

/**
 * Conwip part, comparing ongoing operations count with tickets gauge of the sector
 */
const currentGauge = computed(() => {
  const ticketsGauge = conwipTicketsGaugeBySectorID.value[props.sector.id];

  if (!ticketsGauge) return;

  const {
    underload_tickets_count = 0,
    default_ideal_tickets_count,
    overload_moderate_tickets_count,
    has_underload = false,
    has_overload = false,
  } = ticketsGauge;

  const underload = !has_underload ? 0 : underload_tickets_count;

  if (props.operationsCount < underload) return "underload";

  if (props.operationsCount <= underload + default_ideal_tickets_count)
    return "ideal";

  if (
    has_overload &&
    props.operationsCount <=
      underload + default_ideal_tickets_count + overload_moderate_tickets_count
  )
    return "overload-moderate";

  return "overload-critical";
});
const conwipRate = computed(() => {
  const ticketsGauge = conwipTicketsGaugeBySectorID.value[props.sector.id];

  if (!ticketsGauge) return -1;

  const {
    underload_tickets_count = 0,
    default_ideal_tickets_count,
    overload_moderate_tickets_count,
    has_underload,
  } = ticketsGauge;

  const underload = !has_underload ? 0 : underload_tickets_count;

  if (!currentGauge.value) return -1;

  let delta = 0;

  const gaugeMapping = {
    underload,
    ideal: default_ideal_tickets_count,
    "overload-moderate": overload_moderate_tickets_count,
  };

  for (const [gaugeIdentifier, ticketsCount] of Object.entries(gaugeMapping)) {
    if (currentGauge.value === gaugeIdentifier) {
      if (ticketsCount === 0) return 100;

      return Math.round(((props.operationsCount - delta) / ticketsCount) * 100);
    }

    delta += ticketsCount;
  }

  return 100;
});
const isUnderloadedConwip = computed(() => currentGauge.value === "underload");
const isIdealConwip = computed(() => currentGauge.value === "ideal");
const isModeratelyOverloadedConwip = computed(
  () => currentGauge.value === "overload-moderate",
);
const isCriticallyOverloadedConwip = computed(
  () => currentGauge.value === "overload-critical",
);
/***
 * Default part, comparing ongoing operations quantity with min/max defined in parameters
 */
const defaultRate = computed(() => {
  if (hasNotConfiguredWIPs.value) return -1;
  if (props.ongoing < props.minWip) return 0;
  if (props.maxWip === null) return 0;
  if (props.ongoing > props.maxWip) return -1;

  return Math.round((props.ongoing / props.maxWip) * 100);
});

const hasNotConfiguredWIPs = computed(
  () => props.minWip === null && props.maxWip === null,
);
const isUnderloadedDefault = computed(() => {
  if (hasNotConfiguredWIPs.value) return false;
  if (props.operationsCount === 0) return false;
  if (props.minWip === null) return false;
  return props.ongoing < props.minWip;
});
const isWithinWIPBoundaries = computed(() => {
  if (hasNotConfiguredWIPs.value) return false;
  if (props.operationsCount === 0) return false;
  if (isUnderloadedDefault.value) return false;
  if (isCriticallyOverloadedDefault.value) return false;
  return true;
});
const isModeratelyOverloadedDefault = computed(() => {
  if (hasNotConfiguredWIPs.value) return false;
  if (props.operationsCount === 0) return false;
  if (!selectedConwipGroup.value) return false;
  return false;
});
const isCriticallyOverloadedDefault = computed(() => {
  if (hasNotConfiguredWIPs.value) return false;
  if (props.operationsCount === 0) return false;
  if (props.maxWip === null) return false;
  return props.ongoing > props.maxWip;
});

/**
 * Conditional part, final logic
 */
const wipRate = computed(() => {
  return selectedConwipGroup.value ? conwipRate.value : defaultRate.value;
});

const wipRateStyle = computed(() => {
  if (wipRate.value === -1 || wipRate.value > 100) return {};

  return {
    "--percentage": wipRate.value,
  };
});
const isUnderloaded = computed(() =>
  selectedConwipGroup.value
    ? isUnderloadedConwip.value
    : isUnderloadedDefault.value,
);
const isIdeallyLoaded = computed(() => {
  if (props.operationsCount < 1) return false;
  return selectedConwipGroup.value
    ? isIdealConwip.value
    : isWithinWIPBoundaries.value;
});
const isModeratelyOverloaded = computed(() =>
  selectedConwipGroup.value
    ? isModeratelyOverloadedConwip.value
    : isModeratelyOverloadedDefault.value,
);
const isCriticallyOverloaded = computed(() =>
  selectedConwipGroup.value
    ? isCriticallyOverloadedConwip.value
    : isCriticallyOverloadedDefault.value,
);
const shouldDisplayIcon = computed(() => {
  if (props.operationsCount < 1) return false;
  return selectedConwipGroup.value
    ? !!currentGauge.value
    : !hasNotConfiguredWIPs.value;
});
const iconType = computed(() => {
  if (
    isUnderloaded.value ||
    isModeratelyOverloaded.value ||
    isCriticallyOverloaded.value
  )
    return "alert-triangle";

  return "check";
});
const helperText = computed(() => {
  if (isUnderloaded.value)
    return t("EnCoursColumnSectorWIPRate.helper_text__underload");
  if (isModeratelyOverloaded.value || isCriticallyOverloaded.value)
    return t("EnCoursColumnSectorWIPRate.helper_text__overload");
  return "Ok";
});
const displayedWIP = computed(() => {
  if (!props.operationsCount) return "-";

  const opQuantityText = `${numeral(props.ongoing).format("0.[00]")} ${
    props.sector.unite || t("Commons.hours")
  }`;

  if (!selectedConwipGroup.value) return opQuantityText;

  return `${props.operationsCount} (${opQuantityText})`;
});
</script>

<template>
  <div
    :style="wipRateStyle"
    :class="{
      'overload-critical': isCriticallyOverloaded,
      'overload-moderate': isModeratelyOverloaded,
      ideal: isIdeallyLoaded,
      underload: isUnderloaded,
    }"
    class="en-cours-column-sector-wip-rate fbody-2"
  >
    <div class="en-cours-column-sector-wip-rate__main-text">
      {{ t("ConwipTicketsGaugeTicketsArea.wip") }} :
      <OplitIcon
        v-if="selectedConwipGroup && operationsCount > 0"
        name="ticket"
        stroke="currentColor"
        size="16px"
      />
      <span v-tooltip="displayedWIP" class="semi-bold text-ellipsis">
        {{ displayedWIP }}
      </span>
    </div>

    <div
      v-if="shouldDisplayIcon"
      class="fd-flex-center gap-2 semi-bold fcaption"
    >
      <vue-feather :type="iconType" size="16px" /> {{ helperText }}
    </div>
  </div>
</template>

<style scoped lang="scss">
$baseColorsByClassName: (
  "underload": "cyan",
  "ideal": "green",
  "overload-moderate": "yellow",
);

@mixin get-wip-rate-style($className, $baseColor, $percentage) {
  background: linear-gradient(
    90deg,
    rgba(var(--v-theme-#{$baseColor}-300)) calc($percentage * 1%),
    rgba(var(--v-theme-#{$baseColor}-100)) calc($percentage * 1%),
    rgba(var(--v-theme-#{$baseColor}-100)) 100%
  );
  color: rgb(var(--v-theme-#{$baseColor}-800));
}

.en-cours-column-sector-wip-rate {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  @each $className, $baseColor in $baseColorsByClassName {
    &.#{$className} {
      @include get-wip-rate-style($className, $baseColor, var(--percentage));
    }
  }

  &.overload-critical {
    background: rgb(var(--v-theme-red-700));
    color: white;
  }

  & > div {
    white-space: nowrap;
  }
}

.en-cours-column-sector-wip-rate__main-text {
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;

  > span {
    text-overflow: ellipsis;
  }
}
</style>
