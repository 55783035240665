<template>
  <div
    v-click-outside="onClickOutsideColumn"
    :id="operationsActionsMenuAnchorID"
    :class="{
      'en-cours-column__is-conwip': !!selectedConwipGroup,
    }"
    class="machine-column machine"
  >
    <div v-if="!loading" class="machine-inprogress">
      <template v-if="pg_ops.length > 0">
        <div
          v-show="nbOpsAbove"
          class="sticky-control top-control favorite-card cursor-pointer"
          @click="() => insideContainerScrollTo()"
        >
          <v-tooltip location="start">
            <template v-slot:activator="{props}">
              <v-card :ripple="false" v-bind="props">
                <v-card-text>
                  + {{ nbOpsAbove }}
                  {{
                    $t(
                      `scheduling.${
                        schedulingCurrentGroupBy?.field
                          ? "groups"
                          : "operations"
                      }`,
                      nbOpsAbove,
                    )
                  }}

                  <vue-feather type="arrow-up" size="16" />
                </v-card-text>
              </v-card>
            </template>
            <span>{{ $t("operation.controls.top") }}</span>
          </v-tooltip>
        </div>

        <div
          v-scroll.self="updateCalculations"
          :ref="scrollContainerWrapperRef"
          class="operations-scroll-container-wrapper keep-scrollbar"
        >
          <div
            v-scroll.self="updateCalculations"
            class="operations-scroll-container"
            :ref="scrollContainerRef"
          >
            <template v-if="schedulingCurrentGroupBy?.field">
              <OperationsCardsGroup
                v-bind="operationsCardsCommonProps"
                :operations-list="unbatchedOngoingOps"
                v-on="operationsCardsCommonListeners"
                is-wip
                @toggle-group="updateCalculationsDebounced"
                @operations-grouped="
                  (groupedOPsIDs) => onOperationsGrouped(groupedOPsIDs)
                "
              />

              <OperationsCardsGroupWrapper
                v-for="(ops, batchName) in operationsBatches"
                :key="batchName"
                :count="ops.length"
                :is-selected="isSelectedOperationsGroup(ops)"
                :class="CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS"
                class="grouped"
                :operations-actions-menu-props="operationsActionsMenuProps"
                @move-operations="onMoveOperation"
                @click="() => selectOperations(ops)"
                @toggle="() => updateCalculationsNext()"
              >
                <template #prepend-top-left>
                  <OplitIcon
                    :stroke="variables.newPrimaryDark2"
                    name="squares"
                    size="20px"
                  />
                </template>

                <template #top-left>
                  {{ batchName }}
                </template>

                <template #append-top-left>
                  <IsSavingSpinner
                    :is-changing="havePendingChangesOperations(ops)"
                    :is-saving="areSavingOperations(ops)"
                    :is-selected="isSelectedOperationsGroup(ops)"
                    :has-error="haveErrorsOnSaveOperations(ops)"
                  />
                </template>

                <template #bottom-left> {{ getBatchDetail(ops) }} </template>

                <SchedulingOperationWIPCard
                  v-for="(op, index) in ops"
                  :key="`${op.op_id}-${op.day_date}`"
                  :intersection-observer-root="$refs[scrollContainerWrapperRef]"
                  v-bind="getOperationProps(op, index)"
                  v-on="operationsCardsCommonListeners"
                  is-op-from-group
                  @update-theoric-date="updateTheoricDate"
                />
              </OperationsCardsGroupWrapper>
            </template>

            <template v-else-if="!!selectedConwipGroup && !!mc.ticketsGauge">
              <ConwipTicketsGaugeTicketsArea
                v-for="area in displayedTicketsGaugesArea"
                :key="area"
                :type="getTicketsAreaTypeByName(area)"
                has-left-border
              >
                <template #append-header>
                  {{ getTicketsCountByAreaText(area) }}
                </template>

                <SchedulingOperationWIPCard
                  v-for="(op, index) in opsByTicketsGaugeArea[area]"
                  :key="`${op.op_id}-${op.day_date}`"
                  :intersection-observer-root="$refs[scrollContainerWrapperRef]"
                  v-bind="getOperationProps(op, index)"
                  v-on="operationsCardsCommonListeners"
                  @update-theoric-date="updateTheoricDate"
                />
              </ConwipTicketsGaugeTicketsArea>
            </template>

            <template v-else>
              <SchedulingOperationWIPCard
                v-for="(op, index) in unbatchedOngoingOps"
                :key="`${op.op_id}-${op.day_date}`"
                :intersection-observer-root="$refs[scrollContainerWrapperRef]"
                v-bind="getOperationProps(op, index)"
                v-on="operationsCardsCommonListeners"
                :is-op-from-group="selectedOperationsWithBatchID.length > 0"
                @update-theoric-date="updateTheoricDate"
              />

              <OperationsCardsGroupWrapper
                v-for="(ops, batchName) in operationsBatches"
                :key="batchName"
                :count="ops.length"
                :is-selected="isSelectedOperationsGroup(ops)"
                :class="CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS"
                :operations-actions-menu-props="operationsActionsMenuProps"
                @click="() => selectOperations(ops)"
                @toggle="() => updateCalculationsNext()"
                @move-operations="onMoveOperation"
              >
                <template #prepend-top-left>
                  <OplitIcon
                    :stroke="variables.newPrimaryDark2"
                    name="squares"
                    size="20px"
                  />
                </template>

                <template #top-left>
                  {{ batchName }}
                </template>

                <template #append-top-left>
                  <IsSavingSpinner
                    :is-changing="havePendingChangesOperations(ops)"
                    :is-saving="areSavingOperations(ops)"
                    :is-selected="isSelectedOperationsGroup(ops)"
                    :has-error="haveErrorsOnSaveOperations(ops)"
                  />
                </template>

                <template #bottom-left> {{ getBatchDetail(ops) }} </template>

                <SchedulingOperationWIPCard
                  v-for="(op, index) in ops"
                  :key="`${op.op_id}-${op.day_date}`"
                  :intersection-observer-root="$refs[scrollContainerWrapperRef]"
                  v-bind="getOperationProps(op, index)"
                  v-on="operationsCardsCommonListeners"
                  is-op-from-group
                  @update-theoric-date="updateTheoricDate"
                />
              </OperationsCardsGroupWrapper>
            </template>
          </div>
        </div>

        <div
          v-show="nbOpsBelow"
          class="sticky-control bottom-control favorite-card cursor-pointer"
          @click="() => insideContainerScrollTo('bottom')"
        >
          <v-tooltip location="start">
            <template v-slot:activator="{props}">
              <v-card :ripple="false" v-bind="props">
                <v-card-text>
                  + {{ nbOpsBelow }}
                  {{
                    $t(
                      `scheduling.${
                        schedulingCurrentGroupBy?.field
                          ? "groups"
                          : "operations"
                      }`,
                      nbOpsBelow,
                    )
                  }}

                  <vue-feather type="arrow-down" size="16" />
                </v-card-text>
              </v-card>
            </template>
            <span>{{ $t("operation.controls.bottom") }}</span>
          </v-tooltip>
        </div>
      </template>

      <div
        v-else-if="!!mc.ticketsGauge"
        class="operations-scroll-container-wrapper"
      >
        <ConwipTicketsGaugeTicketsArea
          v-for="area in filteredTicketsGaugeAreas"
          :key="area"
          :type="getTicketsAreaTypeByName(area)"
          has-left-border
        >
          <template #append-header>
            0 / {{ getMaxTicketsByArea(area) }}
          </template>
        </ConwipTicketsGaugeTicketsArea>
      </div>
    </div>

    <EnCoursColumnSectorBlock
      :sector="mc"
      :class="{
        'has-operations': pg_ops.length,
      }"
      :data-testid="`${TEST_IDS.PILOTING_EN_COURS__COLUMN__SECTOR}-${mc.id}`"
    >
      <template v-slot="{sectorName}">
        <div class="d-flex flex-column pa-2">
          <div class="fd-flex-center gap-2">
            <v-tooltip location="bottom">
              <template v-slot:activator="{props}">
                <h5
                  v-bind="props"
                  class="text-grey-900 semi-bold text-ellipsis flex-1 text-16"
                >
                  {{ sliceToMaxLength(sectorName, 25) }}
                  <DevHelper mini :absolute="{top: '-4px', left: '8px'}">
                    ({{ mc.secteur_id }})
                  </DevHelper>
                </h5>
              </template>
              <span>{{ sectorName }}</span>
            </v-tooltip>

            <FButtonIcon
              v-if="
                !!selectedConwipGroup &&
                currentPermissions.scheduling.update_conwip_configuration
              "
              :icon-fill="variables.newSubText"
              :tooltip="$t('EnCoursColumn.tickets_gauge_button_tooltip')"
              icon="ticket-bar"
              size="32"
              icon-size="18"
              is-oplit-icon
              square
              @click="() => openConwipTicketsGaugeSidebar(mc)"
            />
          </div>

          <v-tooltip location="start">
            <template v-slot:activator="{props}">
              <div
                v-bind="props"
                class="d-flex items-center gap-2 text-grey-500 fbody-2"
              >
                <span>{{ $t("scheduling.capa") }} :</span>
                {{ getDisplayedMachineValue(formattedPgCapa) }}
              </div>
            </template>

            <span>
              <strong>{{ $t("scheduling.capa") }} :</strong>
              {{ getDisplayedMachineValue(formattedPgCapa) }}
            </span>
          </v-tooltip>

          <template v-if="!!selectedConwipGroup">
            <v-spacer />

            <FButton
              v-if="hasOperatorsOrMachines && !hideSectorBlockItems"
              outlined
              @click="() => $emit('machine-name-clicked')"
            >
              {{ $t("EnCoursColumn.detailed_view_button_text") }}
            </FButton>
          </template>
        </div>

        <EnCoursColumnSectorWIPRate
          :ongoing="ongoingOpsTotalNbPieces.value"
          :operations-count="ofsCount"
          :sector="mc"
          :min-wip="pg_min_wip"
          :max-wip="pg_max_wip"
        />
      </template>
    </EnCoursColumnSectorBlock>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject, ref, computed, PropType} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import uniqid from "uniqid";
import moment from "moment";
import numeral from "numeral";
import {useI18n} from "vue-i18n";
import {
  usePGWatcher,
  usePGComputedProperties,
  usePGSubscriptions,
} from "@/composables/pgComposable";
import {
  calculateOfDelay,
  pgOpsMapFn,
  getOPUnit,
  type DailyLoadWithSimulationData,
  toSafeString,
  OF_STATUS,
  getOperationStatus,
} from "@oplit/shared-module";
import {
  operationComputedFIFO,
  operationComputedStagnation,
} from "@/tscript/utils/schedulingUtils";

import {OperationsCardsGroup} from "@/components/Scheduling/Operations";
import SchedulingOperationWIPCard from "@/components/Scheduling/Operations/SchedulingOperationWIPCard.vue";
import {
  DATE_DEFAULT_FORMAT,
  PRIORITY_RULES_STORAGE_IDENTIFIER,
  CSS_OPERATION_CARD_CLASS,
  TEST_IDS,
  ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
  CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
  OPERATION_CARD_V_LAZY_MIN_HEIGHT,
} from "@/config/constants";
import {
  SchedulingOperation,
  OpenConwipTicketsGaugeSidebarFunction,
  SectorLike,
  ConwipTicketsGaugeType,
} from "@/interfaces";
import {useSchedulingStore} from "@/stores/schedulingStore";

import {useMainStore} from "@/stores/mainStore";
import {useStorage} from "@vueuse/core";
import EnCoursColumnSectorBlock from "@/components/Scheduling/Piloting/EnCoursColumnSectorBlock.vue";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import ConwipTicketsGaugeTicketsArea from "@/components/Scheduling/Conwip/ConwipTicketsGaugeTicketsArea.vue";
import FButtonIcon from "@/components/Global/Homemade/Buttons/FButtonIcon.vue";
import {usePermissionsStore} from "@/stores/permissionsStore";
import OperationsCardsGroupWrapper from "../Operations/OperationsCardsGroupWrapper.vue";

import {useSelectedOperations} from "@/stores/selectedOperationsStore";
import useComputeQuantityUnit from "@/composables/useComputeQuantityUnit";
import IsSavingSpinner from "@/components/Scheduling/Operations/IsSavingSpinner.vue";
import {type OperationsActionsMenuProps} from "@/components/Scheduling/Operations/OperationsActionsMenu.vue";
import EnCoursColumnSectorWIPRate from "./EnCoursColumnSectorWIPRate.vue";

numeral.locale("fr");

export default defineComponent({
  name: "en-cours-column",
  components: {
    SchedulingOperationWIPCard,
    OperationsCardsGroup,
    EnCoursColumnSectorBlock,
    FButton,
    ConwipTicketsGaugeTicketsArea,
    FButtonIcon,
    OperationsCardsGroupWrapper,
    IsSavingSpinner,
    EnCoursColumnSectorWIPRate,
  },
  props: {
    mc: {type: Object, default: () => ({} as SectorLike)},
    import_id: {type: String, default: ""},
    filters: {type: Array, default: () => []},
    search: {type: String, default: ""},
    ops: {type: Array, default: () => []},
    // toggle for the stagnation display within the OperationCards
    showStagnation: {type: Boolean, default: false},
    // the fields (defined with arbitrary strings) that are hidden within the OperationCards
    hiddenOperationCardsInfos: {type: Array, default: () => []},
    //informs if a request is pending in pg to get the ops
    opsLoading: {type: Boolean, default: false},
    selectedStatus: {type: Array, default: () => []},
    /**
     * array of 2 numbers representing respectively
     * the current index of this instance within the parent array
     * and the total amount of instances within the parent array
     */
    indexTotalCount: {
      type: Array as PropType<number[]>,
      default: () => [-1, -1],
    },
    isOperationOngoing: {type: Boolean, default: false},
    hideSectorBlockItems: {type: Boolean, default: false},
    preventOperationsMoves: {
      type: [Boolean, String],
      default: false,
    },
  },
  setup(props, {emit}) {
    const {t} = useI18n();
    const {pg_init, pg_subscribe} = usePGSubscriptions();
    const schedulingStore = useSchedulingStore();
    const {sendSchedulingLoadEventToBackend} = schedulingStore;
    const {
      selectedSimulation,
      schedulingCurrentGroupBy,
      pgOpsModifications,
      shouldHandleOPDuration,
      selectedConwipGroup,
    } = storeToRefs(schedulingStore);
    const {currentPermissions} = storeToRefs(usePermissionsStore());
    const {
      selectedOperations,
      formattedSelectedOperationsForRetrocompatibility,
      selectedOperationsIDs,
      hasOverlayOpened,
      selectedOperationsWithBatchID,
    } = storeToRefs(useSelectedOperations());
    const {
      havePendingChangesOperations,
      areSavingOperations,
      haveErrorsOnSaveOperations,
      setSelectableOperations,
      toggleSelectedOperations,
      isSelectedOperationsGroup,
      getOperationsAggregatedQuantities,
    } = useSelectedOperations();
    const {operationComputedQuantity} = useComputeQuantityUnit();

    const openConwipTicketsGaugeSidebar =
      inject<OpenConwipTicketsGaugeSidebarFunction>(
        "openConwipTicketsGaugeSidebar",
      );
    const priorityRulesStorageIdentifier = inject<string>(
      "priorityRulesStorageIdentifier",
      PRIORITY_RULES_STORAGE_IDENTIFIER,
    );

    const priorityRules = useStorage(priorityRulesStorageIdentifier, [
      "client_delay",
    ]);
    const isManualSortingEnabled = useStorage(
      ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
      false,
    );

    const mainStore = useMainStore();
    const {userData, apiClient, perimeters, variables} = storeToRefs(mainStore);

    const orderedTicketsGaugeAreas = [
      "underload_tickets_count",
      "default_ideal_tickets_count",
      "overload_moderate_tickets_count",
      "overload_critical_tickets_count",
    ];

    const pg_ops = ref<SchedulingOperation[]>([]);
    const mountedOps = ref<number>(0);
    const scrollContainerWrapperRef = ref<string>(null);
    const pg_max_wip = ref<number>(null);

    const today = computed(() => moment().format(DATE_DEFAULT_FORMAT));

    const parsedPeriod = computed(() => ({
      startDate: today.value,
      endDate: today.value,
    }));
    const sortedOngoingOps = computed<SchedulingOperation[]>(() => {
      let sortingArr: ((o: SchedulingOperation) => unknown)[] = [];
      let sortingOrder: ("asc" | "desc")[] = [];
      let initialPositionOffset = 0;
      if (isManualSortingEnabled.value) {
        sortingArr.push((o) => o?.wip_order ?? Infinity);
        sortingOrder.push("desc");
        initialPositionOffset++;
      }

      // OPL-7469 : sorting on-going ops first
      sortingArr.push((o: SchedulingOperation) =>
        getOperationStatus(o) === OF_STATUS.ON_GOING ? 1 : 0,
      );
      sortingOrder.push("asc");
      initialPositionOffset++;

      sortingArr = [
        ...sortingArr,
        ...priorityRules.value
          .map((rule) => {
            return (o: SchedulingOperation) => {
              const {
                erp_date,
                new_date,
                day_date,
                max_date_of,
                theoric_date,
                fast_track,
                of_delta,
                first_active_op_theoric_date,
                first_active_op_planned_date,
                is_day_date_modified,
                all_ops_done,
              } = o || ({} as SchedulingOperation);

              switch (rule) {
                case "priority":
                  return fast_track || undefined;
                case "client_delay":
                  return calculateOfDelay({
                    initial_date: erp_date,
                    new_date,
                    day_date,
                    theoric_date: max_date_of || theoric_date,
                    first_active_op_theoric_date,
                    first_active_op_planned_date,
                    is_day_date_modified,
                    real_date_delta: of_delta,
                    all_ops_done,
                  });
                case "op_delay":
                  return new_date || day_date;
                case "stagnation":
                  return [
                    operationComputedFIFO(o) ?? -1,
                    operationComputedStagnation(o) ?? -1,
                  ];
                default:
                  return new_date ?? day_date;
              }
            };
          })
          .flat(),
      ];
      sortingOrder = [
        ...sortingOrder,
        ...priorityRules.value
          .map((rule) => {
            if (rule === "stagnation")
              return ["asc", "desc"] as ("asc" | "desc")[];
            return ["op_delay", "priority"].includes(rule) ? "desc" : "asc";
          })
          .flat(),
      ];

      // OPL-7752 : we want to sort by op_order right after op_delay
      if (priorityRules.value.includes("op_delay")) {
        const opOrderSortingInsertionIndex =
          priorityRules.value.indexOf("op_delay") +
          Math.max(initialPositionOffset, 1);
        sortingArr.splice(
          opOrderSortingInsertionIndex,
          0,
          (o) => +o?.op_order || undefined,
        );
        sortingOrder.splice(opOrderSortingInsertionIndex, 0, "desc");
      }

      sortingArr.push((o: SchedulingOperation) => +o?.op_sequence || 0);
      sortingOrder.push("desc");

      const groupAndSort = (
        arr: SchedulingOperation[],
        sortingArr: ((op: SchedulingOperation) => any)[],
        sortOrder: ("asc" | "desc")[],
        groupByKey?: keyof SchedulingOperation,
      ): SchedulingOperation[] => {
        const grouped = groupByKey ? _.groupBy(arr, groupByKey) : {"": arr};
        const sortedGroups = Object.values(grouped).map((group) => {
          if (
            groupByKey &&
            group.length > 0 &&
            !Object.hasOwn(group[0], groupByKey)
          ) {
            group = group.map((op) => {
              return {
                ...op,
                [groupByKey]: t("OperationsCardsGroup.default_group_name"),
              };
            });
          }
          const sortedGroup = _.orderBy(group, sortingArr, sortOrder);
          return {
            operations: sortedGroup,
            group: sortingArr.map((sortFn) => sortFn(group[0])).join("_"),
          };
        });

        const flattenedGroups = _.flatMap(
          _.orderBy(sortedGroups, ["group"], sortOrder),
          (group) => group.operations,
        );

        return flattenedGroups;
      };

      const sortedOPs = groupAndSort(
        pg_ops.value,
        sortingArr,
        sortingOrder,
        schedulingCurrentGroupBy.value?.field,
      );

      emit(
        "operations-sorted",
        props.mc.id || props.mc.secteur_id,
        sortedOPs.map(({op_id}) => op_id),
      );
      return sortedOPs;
    });
    const sortedOngoingOpsIds = computed(() =>
      sortedOngoingOps.value.map((op) => op.op_id),
    );

    const unbatchedOngoingOps = computed<SchedulingOperation[]>(() =>
      sortedOngoingOps.value.filter(({batch_id}) => !batch_id),
    );
    // for certain cases we need to reverse due to the column being flex-reverse'd
    const reverseOrderedTicketsGaugeAreas = computed(() =>
      [...orderedTicketsGaugeAreas].reverse(),
    );
    const filteredTicketsGaugeAreas = computed(() =>
      orderedTicketsGaugeAreas.filter((area) => {
        if (
          area.startsWith("underload") &&
          !props.mc.ticketsGauge?.has_underload
        )
          return false;
        if (area.startsWith("overload") && !props.mc.ticketsGauge?.has_overload)
          return false;
        return true;
      }),
    );
    const displayedTicketsGaugesArea = computed(() =>
      reverseOrderedTicketsGaugeAreas.value.filter(
        (area) => opsByTicketsGaugeArea.value[area],
      ),
    );
    const opsByTicketsGaugeArea = computed(() => {
      if (!props.mc.ticketsGauge) {
        return {
          overload_critical_tickets_count: sortedOngoingOps.value,
        };
      }

      const clone = [...sortedOngoingOps.value].reverse();

      const operationsByArea: Record<string, SchedulingOperation[]> = {};

      for (const area of filteredTicketsGaugeAreas.value) {
        if (props.mc.ticketsGauge[area])
          operationsByArea[area] = clone.splice(0, props.mc.ticketsGauge[area]);
      }

      if (clone.length > 0)
        operationsByArea.overload_critical_tickets_count = clone;

      return operationsByArea;
    });
    const sectorTree = computed(() => {
      if (!props.mc?.secteur_id || !props.mc?.secteur_collection) return {};
      const sectorTree = (
        perimeters.value[props.mc.secteur_collection] || []
      ).find((x) => x.id === props.mc.secteur_id);
      return sectorTree || {};
    });
    const operationsActionsMenuAnchorID = computed(
      () => `operations-actions-menu__anchor-${props.mc.secteur_id}`,
    );
    const operationsActionsMenuProps = computed<OperationsActionsMenuProps>(
      () => {
        const [currentIndex, totalItems] = props.indexTotalCount;
        const hasBatchedOperations = selectedOperations.value.some(
          ({batch_id}) => batch_id,
        );
        const preventVerticalMoves = [true, "vertical"].includes(
          props.preventOperationsMoves,
        );

        return {
          isOperationOngoing: props.isOperationOngoing,
          hideEditDialog: true,
          hideVerticalArrows:
            !isManualSortingEnabled.value ||
            hasBatchedOperations ||
            preventVerticalMoves,
          showBatchModal: true,
          teleport: `:has(> #${operationsActionsMenuAnchorID.value})`,
          displaySide:
            currentIndex > 1 && currentIndex === totalItems ? "left" : "right",
          backgroundColor: "newPrimaryRegular",
          arrowsState: {
            Left: currentIndex > 1,
            Right: currentIndex !== totalItems,
            Up:
              !preventVerticalMoves &&
              !hasBatchedOperations &&
              !selectedOperationsIDs.value.includes(
                sortedOngoingOpsIds.value[0],
              ),
            Down:
              !preventVerticalMoves &&
              !hasBatchedOperations &&
              !selectedOperationsIDs.value.includes(
                sortedOngoingOpsIds.value.at(-1),
              ),
          },
        };
      },
    );
    const operationsCardsCommonProps = computed(() => ({
      showStagnation: props.showStagnation,
      selectedOps: formattedSelectedOperationsForRetrocompatibility.value,
      hiddenInfos: props.hiddenOperationCardsInfos,
      operationsActionsMenuProps: operationsActionsMenuProps.value,
    }));
    const operationsCardsCommonListeners = computed(() => ({
      "operation-mounted": onOperationMounted,
      "move-operation": onMoveOperation,
      "change-status": onUpdateOperation,
      "change-priority": onUpdateOperation,
      "select-card": onSelectCard,
    }));
    const determineOpIndexOfQtyLimit = computed(() => {
      if (!pg_max_wip.value) return -1;
      let targetIdx = -1,
        totalQte = 0;
      for (let i = sortedOngoingOps.value.length - 1; i >= 0; i--) {
        totalQte += +(sortedOngoingOps.value[i].quantite || 0);
        if (totalQte >= pg_max_wip.value) {
          targetIdx = i;
          break;
        }
      }
      return targetIdx;
    });
    const hasOperatorsOrMachines = computed(
      () =>
        props.mc.operateurs?.length > 0 || props.mc.machine_tags?.length > 0,
    );
    const operationsBatches = computed(() => {
      return _.groupBy(
        sortedOngoingOps.value.filter(({batch_id}) => batch_id),
        "batch_name",
      );
    });
    const ofsCount = computed<number>(
      () => Object.keys(_.groupBy(pg_ops.value, "of_id")).length,
    );

    const {canLoadCapa} = usePGComputedProperties({
      parsedPeriod,
      simulation: selectedSimulation.value,
    });

    function propagateOpsChanges(
      updatesList: Partial<DailyLoadWithSimulationData>[],
    ) {
      const tempPgOps = pg_ops.value.map((op: any) => {
        const matchOnNewUpdates = updatesList.find(
          ({op_id}) => op_id === op.op_id,
        );
        if (matchOnNewUpdates) return matchOnNewUpdates;
        else return op;
      });
      pg_ops.value = tempPgOps;
      pgOpsModifications.value = tempPgOps;
    }
    // returns a string without unwanted spaces between "nullish" values
    function stringifyArray(
      array: Array<number | string>,
      separator = " ",
    ): string {
      return array.filter(Boolean).join(separator);
    }

    function getDisplayedMachineValue(value: string) {
      if (pg_ops.value.length < 1) return "-";
      const [first] = pg_ops.value;
      return stringifyArray([value, getOPUnit(first, sectorTree.value)]);
    }
    function onSelectCard(operation?: SchedulingOperation) {
      if (operation?.batch_id) return;
      selectOperations([operation]);
    }
    function onMoveOperation({code}: {code: string}) {
      emit("move-operations", code, sortedOngoingOpsIds.value);
    }
    /**
     * moving an operation triggers an outside click therefore removing all selected cards
     * this function is to prevent this behaviour upon clicking on a menu arrow
     */
    function shouldPreventOutsideClick(eventTarget: HTMLElement) {
      if (!eventTarget) return false;

      if (
        [
          "feather-arrow-left",
          "feather-arrow-right",
          CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
        ].some((key) => eventTarget.classList?.contains(key))
      )
        return true;
      return shouldPreventOutsideClick(eventTarget.parentElement);
    }
    async function onClickOutsideColumn({target}: {target: HTMLElement}) {
      if (!selectedOperations.value.length) return;
      if (hasOverlayOpened.value) return;
      if (shouldPreventOutsideClick(target)) return;
      selectedOperations.value = [];
      onSelectCard();
    }
    function onOperationMounted() {
      mountedOps.value += 1;
    }
    async function onUpdateOperation({
      update,
      operation,
    }: {
      update: Record<string, unknown>;
      operation: SchedulingOperation;
    }) {
      if (!operation) return;

      // visually apply modifications before the backend response
      const opIndex = pg_ops.value.findIndex(
        (op) => op.op_id === operation.op_id,
      );
      if (opIndex === -1) return;
      pg_ops.value[opIndex] = {
        ...pg_ops.value[opIndex],
        ...update,
      };

      //OPL-4456 : nouveau format des évènements d'ordo
      const {updated_values} = await sendSchedulingLoadEventToBackend({
        data: [{initial: operation, update}],
        should_return_updated_values: true,
        should_handle_op_duration: shouldHandleOPDuration.value,
      });
      const mappedValues = pgOpsMapFn(updated_values, {
        keepOpsDone: true,
      });
      propagateOpsChanges(mappedValues);
    }
    function getOperationProps(operation: SchedulingOperation, index: number) {
      return {
        ...operationsCardsCommonProps.value,
        id: operation.op_id,
        order: index + 1,
        isOngoingLimit: index === determineOpIndexOfQtyLimit.value,
        doNotRenderLazy: index > sortedOngoingOps.value.length - 5,
        class: "scheduling-operation-card__en-cours",
        operation,
      };
    }
    function getMaxTicketsByArea(area: string): string | number {
      return area === "overload_critical_tickets_count"
        ? "∞"
        : props.mc.ticketsGauge?.[area];
    }
    function getTicketsCountByAreaText(area: string) {
      return `${
        opsByTicketsGaugeArea.value[area].length
      } / ${getMaxTicketsByArea(area)}`;
    }
    function getTicketsAreaTypeByName(area: string): ConwipTicketsGaugeType {
      switch (area) {
        case "underload_tickets_count":
          return "underload";
        case "overload_moderate_tickets_count":
          return "overload-moderate";
        case "overload_critical_tickets_count":
          return "overload-critical";
        default:
          return "ideal";
      }
    }

    function onOperationsGrouped(groupedOPsIDs: string[][]): void {
      emit(
        "operations-grouped",
        props.mc.id || props.mc.secteur_id,
        groupedOPsIDs,
      );
    }
    function selectOperations(operations: SchedulingOperation[]) {
      toggleSelectedOperations(operations);

      setSelectableOperations(sortedOngoingOps.value);
    }
    function getBatchDetail(operations: SchedulingOperation[]) {
      const {quantite_op: quantite, quantite_of} =
        getOperationsAggregatedQuantities(operations);

      const groupOperationsReducedAsAnObject = {
        quantite,
        quantite_of,
        unite: operationComputedQuantity(operations[0]).unite,
        unite_of: operationComputedQuantity(operations[0]).unite_of,
      };

      return operationComputedQuantity(groupOperationsReducedAsAnObject, true)
        .txt;
    }

    return {
      getBatchDetail,
      isSelectedOperationsGroup,
      selectOperations,
      pg_ops,
      pg_capa: ref<unknown[]>(null),
      loading: ref<boolean>(false),
      scrollContainerRef: ref<string>(null),
      pg_min_wip: ref<number>(null),
      pg_max_wip,
      //data to handle the number of hidden cards depending on the column height
      totalElementsHeight: ref<number>(0),
      wrapperHeight: ref<number>(0),
      childrenHeightsArr: ref<number[]>([]),
      nbOpsAbove: ref<number>(0),
      nbOpsBelow: ref<number>(0),
      hasMoreOperationsThanSpace: ref(false),
      // the operations-scroll-container overflow css prop is required to display full borders
      // we can't overflow directly or the calculations made within updateCalculations will be
      // restricted by this css prop
      canContainerOverflow: ref<boolean>(false),
      TEST_IDS,
      // counting operations that have mounted
      mountedOps,
      selectedSimulation,
      schedulingCurrentGroupBy,
      canLoadCapa,
      pg_init,
      pg_subscribe,
      userData,
      apiClient,
      perimeters,
      parsedPeriod,
      shouldHandleOPDuration,
      sendSchedulingLoadEventToBackend,
      sortedOngoingOps,
      sortedOngoingOpsIds,
      stringifyArray,
      sectorTree,
      getDisplayedMachineValue,
      onClickOutsideColumn,
      operationsActionsMenuAnchorID,
      operationsActionsMenuProps,
      operationsCardsCommonProps,
      scrollContainerWrapperRef,
      priorityRules,
      isManualSortingEnabled,
      variables,
      opsByTicketsGaugeArea,
      orderedTicketsGaugeAreas,
      getOperationProps,
      getTicketsCountByAreaText,
      selectedConwipGroup,
      displayedTicketsGaugesArea,
      getMaxTicketsByArea,
      hasOperatorsOrMachines,
      getTicketsAreaTypeByName,
      reverseOrderedTicketsGaugeAreas,
      openConwipTicketsGaugeSidebar,
      filteredTicketsGaugeAreas,
      onOperationMounted,
      operationsCardsCommonListeners,
      currentPermissions,
      onSelectCard,
      onOperationsGrouped,
      operationsBatches,
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
      unbatchedOngoingOps,
      onMoveOperation,
      havePendingChangesOperations,
      areSavingOperations,
      haveErrorsOnSaveOperations,
      getOperationsAggregatedQuantities,
      selectedOperationsWithBatchID,
      ofsCount,
    };
  },
  computed: {
    formattedPgCapa() {
      const {pg_capa, pg_ops} = this;
      if (!pg_ops.length) return "-";
      return numeral(+pg_capa).format("0.[00]");
    },
    simulation() {
      return this.selectedSimulation;
    },

    ongoingOpsTotalNbPieces() {
      const {quantite_op} = this.getOperationsAggregatedQuantities(this.pg_ops);

      return {
        value: quantite_op,
        text: numeral(quantite_op).format("0.[00]"),
      };
    },
    debouncedCalculationsWatcher(): string {
      return JSON.stringify([
        this.showStagnation,
        this.hiddenOperationCardsInfos,
        this.priorityRules,
        this.isManualSortingEnabled,
      ]);
    },
    nextTickCalculationsWatcher(): string {
      return JSON.stringify([
        this.mountedOps,
        this.filters,
        this.activeGrouping,
        this.selectedStatus,
        this.search,
      ]);
    },
  },
  watch: {
    selectedSimulation() {
      this.loadTotalCapa();
    },
    ops: {
      immediate: true,
      handler: function (val: any) {
        this.pg_ops = pgOpsMapFn(Array.from(val || []), {
          removeSmoothedDuplicates: true,
        });
      },
      deep: true,
    },
    debouncedCalculationsWatcher() {
      this.updateCalculationsDebounced();
    },
    nextTickCalculationsWatcher() {
      this.updateCalculationsNext();
    },
    schedulingCurrentGroupBy() {
      // due to the content of the column changing, we need to reset this to have proper calculations
      this.canContainerOverflow = false;
      this.updateCalculationsDebounced();
    },
  },
  created() {
    this.scrollContainerWrapperRef = uniqid("scroll_");
    this.scrollContainerRef = uniqid("scroll_");

    window.addEventListener("resize", this.updateCalculationsNext);

    this.pg_subscriptions = _.debounce(this.pg_subscriptions, 150);
    this.loadTotalCapa = _.debounce(this.loadTotalCapa, 150);
    this.loadFieldValue = _.debounce(this.loadFieldValue, 150);

    usePGWatcher(this);
  },
  methods: {
    pg_subscriptions() {
      if (!this.canLoadCapa) return;
      this.pg_init();
      this.pg_capa = null;
      this.pg_subscribe(["daily_capa", "default_capa"], () => {
        this.loadTotalCapa();
      });
    },
    async loadTotalCapa() {
      if (this.hideSectorBlockItems) return;

      this.loading = true;
      const {sectorTree, userData, simulation, parsedPeriod, canLoadCapa} =
        this;
      const {client_id} = userData || {};
      const {startDate, endDate} = parsedPeriod || {};

      if (!canLoadCapa) {
        this.loading = false;
        return;
      }
      const params = {
        query_type: "daily_total_capa",
        client_id,
        simulation_id: simulation.id,
        startDate,
        endDate,
        sector_tree: sectorTree,
        load_auto_orga: true,
      };

      const results = await this.apiClient.pgCustom(params);
      this.pg_capa = _.get(results, [0, "daily_capa"]) || 0;

      this.loading = false;
    },
    // loads the value for min_wip and max_wip that will determine whether or not a machine center is undercharged/overloaded
    async loadFieldValue() {
      const {userData, sectorTree: sector_tree, simulation} = this;
      const {client_id} = userData || {};
      const defaultDate = "1970-01-01";
      ["min_wip", "max_wip"].forEach((model: string) => {
        const params = {
          query_type: "msd_field_value",
          client_id,
          startDate: defaultDate,
          endDate: defaultDate,
          sector_tree,
          simulation,
          field: model,
          load_calendar: true,
        };

        /**
         * FIXME: we always return 0 even in the case of non-definition
         */
        this.apiClient.pgCustom(params).then((result: any) => {
          const {field_value} = result || {};
          this[`pg_${model}`] = field_value;
        });
      });
    },
    insideContainerScrollTo(direction = "top") {
      if (!this.$refs[this.scrollContainerWrapperRef]) return;
      this.$refs[this.scrollContainerWrapperRef].scrollTo({
        top: direction === "bottom" ? 0 : this.totalElementsHeight * -1.15,
        behavior: "smooth",
      });
    },
    /**
     * this function has to be called to update the above/below ops whenever
     * - the available size above the columns changes: resizing vertically / zooming in/out
     * - the amount of operations within a column changes (triggering therefore the above logic)
     */
    updateCalculationsNext() {
      this.canContainerOverflow = false;
      this.$nextTick(this.updateCalculations);
    },
    updateCalculationsDebounced: _.debounce(function (this: any) {
      this.updateCalculations();
    }, 50),
    updateCalculations() {
      const scrollContainer = this.$refs[this.scrollContainerRef];
      const scrollContainerWrapper = this.$refs[this.scrollContainerWrapperRef];

      if (!scrollContainer) return;

      let childrenList = scrollContainer.querySelectorAll(
        `.${CSS_OPERATION_CARD_CLASS}, .operations-cards-group-wrapper__container`,
      );

      if (!childrenList.length) return;

      let totalElementsHeight =
        childrenList.length * OPERATION_CARD_V_LAZY_MIN_HEIGHT;

      /**
       * when the operations are not grouped, they are displayed directly as OperationCards with a v-for
       * and therefore are direct children of the scroll container.
       * when they are grouped, they are passed as a prop to the OperationsCardsGroup component.
       * due to vue's definition not allowing v-for's on the root template's tag,
       * this component has a wrapping <div> containing all the different groups of cards.
       * for this use case of grouped operations, the list of children in which we are interested to determine
       * the total height of the scroll container is this wrapping div's children and not the scroll container direct children.
       */
      if (this.schedulingCurrentGroupBy?.field) {
        const operationsCardsGroupWrappingDiv = scrollContainer.children[0];
        childrenList = operationsCardsGroupWrappingDiv.children;
        totalElementsHeight = operationsCardsGroupWrappingDiv.offsetHeight;
      }

      const childrenHeightsArr = Array.from(
        childrenList,
        ({offsetHeight}: {offsetHeight: number}): number =>
          offsetHeight || OPERATION_CARD_V_LAZY_MIN_HEIGHT,
      );

      const sumChildren = _.sum(childrenHeightsArr);
      totalElementsHeight = sumChildren;

      const wrapperHeight = scrollContainerWrapper.offsetHeight;
      const scrollTop = scrollContainerWrapper.scrollTop; //negative value
      const topHiddenHeight = totalElementsHeight - wrapperHeight + scrollTop;
      const bottomHiddenHeight = -scrollTop;

      this.hasMoreOperationsThanSpace =
        wrapperHeight < totalElementsHeight && !this.schedulingCurrentGroupBy;

      //calculate the number of hidden ops
      let nbOpsAbove = 0,
        nbOpsBelow = 0,
        passedHeight = 0;
      //hidden ops above
      for (let i = 0; i < childrenHeightsArr.length; i++) {
        const itemHeight: number = childrenHeightsArr[i];
        if (itemHeight > topHiddenHeight - passedHeight) break;
        nbOpsAbove += 1;
        passedHeight += itemHeight;
      }
      passedHeight = 0;
      //hidden ops below
      for (let i = childrenHeightsArr.length - 1; i >= 0; i--) {
        const itemHeight: number = childrenHeightsArr[i];
        if (itemHeight > bottomHiddenHeight - passedHeight) break;
        nbOpsBelow += 1;
        passedHeight += itemHeight;
      }

      //pass the values to the state
      this.totalElementsHeight = totalElementsHeight;
      this.wrapperHeight = wrapperHeight;
      this.childrenHeightsArr = childrenHeightsArr;
      this.nbOpsAbove = nbOpsAbove;
      this.nbOpsBelow = nbOpsBelow;
      // we overflow after the calculations or these will be erroneous due to the working of the overflow prop
      this.canContainerOverflow = true;
    },
    sliceToMaxLength(
      str: string,
      maxLength: number,
      forceFullText = false,
    ): string {
      return str?.length < maxLength || forceFullText
        ? str
        : `${toSafeString(str).slice(0, maxLength - 3)}...`;
    },
    updateTheoricDate(data: any) {
      const {op_id, theoric_date} = data;
      this.pg_ops = this.pg_ops.map((x: any) =>
        x.op_id === op_id ? {...x, theoric_date} : x,
      );
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateCalculationsNext);
  },
});
</script>
<style scoped lang="scss">
.scroll-x-hidden {
  overflow-x: hidden !important;
}
.machine {
  display: inline-block;
  &-column {
    --column-padding: 8px;

    background: rgb(var(--v-theme-grey-25));
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;

    & .sticky-control {
      position: sticky;
      width: 100%;
      font-weight: bold;
      border: 1px solid rgb(var(--v-theme-newSelected));

      & > .v-card {
        background: rgb(var(--v-theme-newSubBackground));
        border-color: rgb(var(--v-theme-newSelected));
        border-style: solid;
        box-shadow: none;

        & .v-card-text {
          color: rgb(var(--v-theme-newSubText)) !important;
          font-size: 14px;
          font-weight: bold;
          padding: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }
      }

      &.top-control {
        top: 0;

        & > .v-card {
          border-width: 0 0 2px 0;
          border-radius: 8px 8px 0 0;
        }
      }

      &.bottom-control {
        top: 450px;

        & > .v-card {
          border-width: 4px 0 0 0;
          border-radius: 0 0 8px 8px;
        }
      }
    }

    & :deep(.conwip-tickets-gauge-tickets-area) {
      padding: var(--column-padding);
    }

    & :deep(.conwip-tickets-gauge-tickets-area__content) {
      display: flex;
      flex-direction: column-reverse;
    }

    & :deep(.conwip-tickets-gauge-tickets-area__header) {
      padding: 0 4px;
    }
  }
  &-inprogress {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    & .operations-scroll-container-wrapper {
      height: 0px;
      flex: 1 1 auto;
      overflow-y: auto;
      min-height: 0px;
      display: flex;
      flex-direction: column-reverse;
      overflow-x: hidden;

      &.keep-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      & .operations-scroll-container {
        border-radius: 8px;
        &.overflow-auto {
          overflow: auto;
        }
      }

      & .operation-card {
        padding: 0 !important;
      }
    }
  }

  &-ended {
    border: 1px solid rgb(var(--v-theme-newSelected));
    border-radius: 0px 0px 8px 8px;
    background-color: rgb(var(--v-theme-newLayerBackground));
    height: 320px;
  }
}

.machine-column :deep(.operation-card .scheduling-operation-card__content) {
  border-radius: 8px;
}

.machine-column:not(.en-cours-column__is-conwip) {
  & .operations-scroll-container-wrapper {
    padding: var(--column-padding);
  }

  div:not(.operations-cards-group-wrapper__content)
    > .scheduling-operation-card__en-cours {
    &:last-child :deep(.scheduling-operation-card__content) {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    &:first-child :deep(.scheduling-operation-card__content) {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }

    &:deep(.scheduling-operation-card__content:not(.selected-card)) {
      border-top: 1px solid rgb(var(--v-theme-newSelected));
    }

    &:not(:first-child) {
      margin-top: 8px;
      border-radius: 8px;
    }
  }

  .sticky-control {
    width: 100%;
  }
}

.en-cours-column__is-conwip {
  & .scheduling-operation-card__en-cours {
    &:deep(.scheduling-operation-card__content) {
      margin-top: 8px;
    }
  }

  & .operations-scroll-container {
    border: none !important;
    border-radius: 0 !important;
  }
}

[id^="operations-actions-menu__anchor-"] {
  position: relative;

  &:deep(~ .operation-card-actions-menu) {
    --spacing: 8px;

    top: initial !important;
    bottom: var(--sector-block--height);
    padding: 6px;

    &.display-right {
      transform: translateX(var(--spacing));
    }

    &.display-left {
      transform: translateX(calc(var(--spacing) * -1));
    }
  }
}
</style>
<style lang="scss">
.operations-scroll-container-wrapper {
  & .oplist-wrapper {
    padding: 0 !important;

    &:last-child {
      .operation-card:last-child .scheduling-operation-card__content {
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }
    }

    &:first-child {
      & .oplist-title {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }

    &:last-child {
      & .oplist-title:not(.open) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .oplist-container {
      padding-bottom: 8px;
    }
  }

  & .operation-card {
    & > .v-card {
      border-radius: 0;
      border: none !important; /* removing the previous style applied so that only the following is */
      cursor: unset;
    }

    &:not(:first-child) {
      & > .v-card {
        border-top: 2px solid rgb(var(--v-theme-newSelected)) !important;
      }
    }
  }
}

.operations-scroll-container {
  border-top: 1px solid transparent !important;
}

.machine-inprogress
  .operations-scroll-container
  .operations-cards-group-wrapper__container {
  border: 1px solid rgb(var(--v-theme-newSelected));

  &:not(:first-child) {
    margin-top: 8px;
  }

  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.operations-cards-group-wrapper__container {
  background: rgb(var(--v-theme-grey-50));

  .operations-cards-group-wrapper__content {
    padding: 0 8px 8px;

    & .operation-card {
      &:not(:first-child) {
        margin-top: -1px;
      }

      &:first-child .scheduling-operation-card__content {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child .scheduling-operation-card__content {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      & .scheduling-operation-card__content {
        border-radius: 0;
      }
    }
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.operations-cards-group-wrapper__container:first-child:last-child {
  border-radius: inherit;
  overflow: hidden;
}

.machine-status {
  svg {
    stroke-width: 3px;
  }
}
</style>
