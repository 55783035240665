<template>
  <div
    v-for="(opList, index) in operationsListGrouped"
    :key="`wrapper-${index}-groupby-${schedulingCurrentGroupBy.id}`"
    :class="[
      {'day-view': selectedView === 'day_view'},
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
    ]"
    class="oplist-wrapper"
    :data-testid="`operations-cards-group-${opList[groupNameAttribute]}-${dateIso}`"
    v-click-outside="onClickOutsideCard"
  >
    <OperationsActionsMenu
      class="oplist-actions-menu"
      :show-actions-menu="isDisplayedActionsMenu(opList)"
      :arrows-state="getComputedArrowsState(opList, index)"
      :display-side="displayActionsMenuSide"
      :top="actionsMenuTopValue"
      :shift-pagination="isPast ? undefined : shiftPagination"
      :has-done-ops="hasDoneOps"
      :selected-ops="selectedOps"
      v-bind="operationsActionsMenuProps"
      @trigger-key-event="triggerKeyEvent"
      @change-operation-shift="
        (direction) => $emit('change-operation-shift', direction, opList.key)
      "
    />

    <div
      :class="[
        {
          [CSS_OPERATIONS_CARDS_GROUP_SELECTED_CLASS]: isSelected(opList.data),
          open: isOpen(opList.key),
          single: operationsListGrouped.length === 1,
          'has-split': shouldAlertSplitOf && opList.has_split,
        },
        getGroupColor(opList),
        'oplist-title fbody-2',
      ]"
    >
      <div :class="cssGapClass" class="fd-flex-center cursor-pointer">
        <div
          :class="[{'flex-column': selectedView !== 'day_view'}, cssGapClass]"
          class="d-flex flex-1 min-width-0"
          @click="addGroupToSelectedList(opList.data)"
        >
          <div
            v-tooltip="opList['groupDisplayName']"
            class="op-list-name fbody-1 text-ellipsis flex-1"
            data-testid="op-list-name"
          >
            {{ opList["groupDisplayName"] }}
          </div>

          <div
            v-tooltip="opList.computedQuantity"
            :class="{'flex-1': selectedView !== 'day_view'}"
            class="text-newSubText operations-cards-group__quantity text-ellipsis"
            data-testid="op-list-quantity"
          >
            {{ opList.computedQuantity }}
          </div>
        </div>

        <div
          :class="[{'flex-column': selectedView !== 'day_view'}, cssGapClass]"
          class="fd-flex-center print-hide"
          @click.stop="toggleGroup(opList)"
        >
          <div class="position-relative">
            <IsSavingSpinner
              class="mr-2 absolute-spinner-pos"
              :is-changing="groupHasChangesPending(opList.data)"
              :is-saving="groupIsSaving(opList.data)"
              :is-selected="isSelected(opList.data)"
              :has-error="groupHasErrorsOnSave(opList.data)"
            />

            <div class="fd-flex-center">
              <div
                v-if="
                  isManualSortingEnabled && hasManuallySortedOps(opList.data)
                "
                class="mr-2"
              >
                <DevHelper class="text-newMainText">{{
                  `(${getLowestWIPOrder(opList.data)})`
                }}</DevHelper>

                <OplitIcon
                  :fill="variables.newPrimaryRegular"
                  name="hand-swipe-left"
                  size="20px"
                />
              </div>

              <div
                :class="`oplist-length px-2 str-len-${
                  `${opList.data.length}`.length
                }`"
                data-testid="op-list-length"
              >
                {{ opList.data.length }}
              </div>
            </div>
          </div>

          <vue-feather
            :type="isOpen(opList.key) ? 'chevron-up' : 'chevron-down'"
            size="20px"
            :stroke="variables.newSubText"
            :data-testid="`toggle-op-group-${
              isOpen(opList.key) ? 'up' : 'down'
            }`"
          />
        </div>
      </div>
    </div>
    <transition name="slide-down">
      <div v-if="isOpen(opList.key)" class="oplist-container">
        <Component
          v-for="(op, index) in opList.data"
          :is="getOperationDisplayComponent"
          :id="op.op_id"
          :key="op.op_id"
          :operation="op"
          :order="index + 1"
          :selected-card="selectedCard"
          :hidden-infos="hiddenInfos"
          :show-stagnation="showStagnation"
          :selected-ops="selectedOps"
          :is-last-op="index === opList.data.length - 1"
          is-op-from-group
          :do-not-render-lazy="doNotRenderLazy"
          :has-done-ops="hasDoneOps"
          @change-status="changeStatus"
          @change-priority="changePriority"
          @select-card="selectCard"
          @move-operation="moveOperation"
          @save-move-operation="saveMoveOperation"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref, PropType} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import OperationsActionsMenu from "@/components/Scheduling/Operations/OperationsActionsMenu.vue";
import SchedulingOperationRow from "@/components/Scheduling/Operations/SchedulingOperationRow.vue";
import SchedulingOperationCard from "@/components/Scheduling/Operations/SchedulingOperationCard.vue";
import SchedulingOperationWIPCard from "@/components/Scheduling/Operations/SchedulingOperationWIPCard.vue";
import IsSavingSpinner from "@/components/Scheduling/Operations/IsSavingSpinner.vue";
import {
  getArrowsState,
  getColorCategoryClass,
  getFirstSelected,
  hasArrayDoneOperations,
  onClickOutsideSchedulingOperation,
} from "@/tscript/utils/schedulingUtils";
import {
  CSS_OPERATIONS_CARDS_GROUP_SELECTED_CLASS,
  CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
  ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
} from "@/config/constants";
import {
  GenericObject,
  SchedulingOperation,
  SchedulingOperationCardsGroup,
  OperationsActionsMenuSelectedOps,
} from "@/interfaces";
import useComputeQuantityUnit from "@/composables/useComputeQuantityUnit";
import useSchedulingGroups from "@/composables/scheduling/useSchedulingGroups";
import {useSchedulingStore} from "@/stores/schedulingStore";

import {useMainStore} from "@/stores/mainStore";
import {useIsArchivedSimulation} from "@/composables/useIsArchivedSimulation";
import {PARAMETERS_IMPORT_PARSING_RULES_CONCATENATION_EXPRESSION} from "@oplit/shared-module";
import {useSchedulingTag} from "@/composables/scheduling/useSchedulingTag";
import {useStorage} from "@vueuse/core";
import {useSelectedOperations} from "@/stores/selectedOperationsStore";

numeral.locale("fr");

type OperationsActionsMenuProps = InstanceType<
  typeof OperationsActionsMenu
>["$props"];

// top padding applied to operation cards
const operationCardPaddingTopValue = 8;

export default defineComponent({
  name: "operations-cards-group",
  components: {OperationsActionsMenu, IsSavingSpinner},
  props: {
    isPast: {type: Boolean, default: false},
    secteurId: {type: String, default: ""},
    dateIso: {type: String, default: ""},
    operationsList: {type: Array, default: () => []},
    pg_ops: {type: Array as PropType<SchedulingOperation[]>, default: () => []},
    // isSyntheticView: {type: Boolean, default: false},
    selectedCard: {type: Object, default: () => ({})},
    operation: {type: Object, default: () => ({})},
    disabled: {type: Boolean, default: false},
    order: {type: Number, default: 0},
    displayActionsMenuSide: {type: String, default: "right"},
    maille: {type: String, default: ""},
    // this prop is passed to & interpreted in SchedulingOperationWrapper (see the component for infos)
    hiddenInfos: {type: Array, default: () => []},
    // this prop is passed to & interpreted in SchedulingOperationWrapper (see the component for infos)
    showStagnation: {type: Boolean, default: false},
    // FIXME
    selectedView: {type: String},
    shiftPagination: Object as PropType<{current: number; max: number}>,
    doNotRenderLazy: Boolean,
    teleport: {type: String, default: null},
    operationsActionsMenuProps: {
      type: Object as PropType<OperationsActionsMenuProps>,
      default: () => ({} as OperationsActionsMenuProps),
    },
    shouldAlertSplitOf: {type: Boolean, default: false},
    selectedOps: {
      type: Object as PropType<OperationsActionsMenuSelectedOps>,
      default: () => ({} as OperationsActionsMenuSelectedOps),
    },
    isWip: {type: Boolean, default: false},
  },
  emits: [
    "operation-mounted",
    "select-card",
    "save-move-operation",
    "move-operation",
    "change-status",
    "change-priority",
    "change-operation-shift",
    "toggle-group",
    "operations-grouped",
  ],
  setup(props, {emit}) {
    const schedulingStore = useSchedulingStore();
    const {
      schedulingCurrentGroupBy,
      currentlySavingOfIds,
      currentlyChangingOfIds,
      canOperateOnOperationCards,
      schedulingTags,
    } = storeToRefs(schedulingStore);
    const {operationComputedQuantity} = useComputeQuantityUnit();

    const mainStore = useMainStore();
    const {clientParameters, variables} = storeToRefs(mainStore);

    const isManualSortingEnabled = useStorage(
      ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
      false,
    );

    const {isArchivedSimulation} = useIsArchivedSimulation();

    const {toggledGroups, toggleGroup, isOpen} = useSchedulingGroups({emit});

    const {getSchedulingTag} = useSchedulingTag();
    const concatenationExpression: string =
      PARAMETERS_IMPORT_PARSING_RULES_CONCATENATION_EXPRESSION;

    const {hasOverlayOpened} = storeToRefs(useSelectedOperations());

    const selectedOpsIDs = computed(() => Object.keys(props.selectedOps));
    const getOperationDisplayComponent = computed(() => {
      if (props.isWip) return SchedulingOperationWIPCard;

      return props.selectedView === "day_view"
        ? SchedulingOperationRow
        : SchedulingOperationCard;
    });
    const cssGapClass = computed(() =>
      props.selectedView === "day_view" ? "gap-6" : "gap-2",
    );

    const hasDoneOps = computed(() => {
      const fullSelectedOPs = props.pg_ops.filter((op) =>
        selectedOpsIDs.value.includes(op.op_id),
      );

      return hasArrayDoneOperations(fullSelectedOPs);
    });

    function changeOperationShift(direction: string, key: GenericObject) {
      emit("change-operation-shift", direction, key);
    }
    function groupHasChangesPending(data: SchedulingOperation[]) {
      return data.some((op) => currentlyChangingOfIds.value[op.of_id]);
    }
    function groupIsSaving(data: SchedulingOperation[]) {
      return data.some((op) => currentlySavingOfIds.value[op.of_id]);
    }
    function groupHasErrorsOnSave(data: SchedulingOperation[]) {
      return data.some((op) => currentlySavingOfIds.value[op.of_id] === false);
    }
    function onClickOutsideCard(event: Event): void {
      if (hasOverlayOpened.value) return;
      return onClickOutsideSchedulingOperation(event)
        ? emit("select-card")
        : null;
    }

    function hasManuallySortedOps(data: SchedulingOperation[]) {
      return data.some(({wip_order}) => wip_order != null);
    }
    function getLowestWIPOrder(data: SchedulingOperation[]) {
      return Math.min(...data.map(({wip_order}) => wip_order ?? Infinity));
    }

    return {
      /**
       * top value for the absolutely-positioned actions menu
       * aligns either to the top of the box (if the group is selected)
       * or to the selected card within (if existing)
       */
      actionsMenuTopValue: ref<number>(operationCardPaddingTopValue),
      /**
       * variable used as a condition for the display of the actions menu
       * used to have a nicer UI update (since we debounce the setActionsMenuTopValue method)
       */
      hasDebounced: ref<boolean>(false),
      // see listenKeyEvents()
      hasKeyEventsListenerInitialized: ref<boolean>(false),
      groupNameAttribute: ref<string>("id"),
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
      CSS_OPERATIONS_CARDS_GROUP_SELECTED_CLASS,
      schedulingCurrentGroupBy,
      operationComputedQuantity,
      clientParameters,
      variables,
      isArchivedSimulation,
      getOperationDisplayComponent,
      cssGapClass,
      changeOperationShift,
      toggledGroups,
      toggleGroup,
      isOpen,
      getSchedulingTag,
      groupHasChangesPending,
      groupIsSaving,
      groupHasErrorsOnSave,
      canOperateOnOperationCards,
      schedulingTags,
      concatenationExpression,
      onClickOutsideCard,
      hasDoneOps,
      selectedOpsIDs,
      hasManuallySortedOps,
      getLowestWIPOrder,
      isManualSortingEnabled,
    };
  },
  computed: {
    operationsListGrouped(): SchedulingOperationCardsGroup[] {
      const {
        operationsList,
        schedulingCurrentGroupBy,
        secteurId,
        dateIso,
        isPast,
        groupNameAttribute,
        shiftPagination,
        concatenationExpression,
        getSchedulingTag,
      } = this;
      if (!schedulingCurrentGroupBy?.field) return [];
      const spreadOperationsList = (operationsList || []).map(
        (x: SchedulingOperation) => {
          const {internal_extra_infos, extra_infos} = x;
          return {
            ...(extra_infos || {}),
            ...(internal_extra_infos || {}),
            ...(x || {}),
          };
        },
      );
      let groupedSortedOperations = _.groupBy(
        spreadOperationsList,
        (x: SchedulingOperation) => _.get(x, schedulingCurrentGroupBy.field),
      ) as unknown as SchedulingOperationCardsGroup[];

      /**
       * when we group on certain fields that are falsy from the operations themselves
       * we end up having falsy keys within the grouped operations
       * this is the case for the group by customer since the operation cards have no "customer" field
       * NB: the list of fields are within the computed groupeByFiltersList() in SchedulingGroupByMenu
       * on such cases, we want to display a default group name which value is defined by @defaultGroupName below
       * we also need to delete such keys for a proper rendering
       * NB: only the falsy values within @arrayKeyMissingWithinOp are considered
       */

      const arrayKeyMissingWithinOp: string[] = [null, undefined, ""].filter(
        (value: string) => groupedSortedOperations?.[value],
      );

      const defaultGroupName: string = this.$t(
        "OperationsCardsGroup.default_group_name",
      );

      for (const key of arrayKeyMissingWithinOp) {
        groupedSortedOperations[defaultGroupName] = [
          ...(groupedSortedOperations[defaultGroupName] || []),
          ...(groupedSortedOperations[key] || []),
        ];

        delete groupedSortedOperations[key];
      }

      const keys = Object.keys(groupedSortedOperations);

      const groupedByKey = keys.map(
        (key: string): SchedulingOperationCardsGroup => {
          const group = groupedSortedOperations[key];
          const [firstOp] = group;
          let name = (key.charAt(0).toUpperCase() + key.slice(1)).replaceAll(
            concatenationExpression,
            " - ",
          );
          let groupDisplayName = name;
          if (schedulingCurrentGroupBy.field === "tags") {
            const [firstTag] = firstOp?.tags || [];
            groupDisplayName = getSchedulingTag(firstTag)?.label || name;
          }
          const listKey = {
            name,
            secteurId: secteurId || firstOp.secteur_id,
            dateIso,
            isPast,
            shiftIndex: shiftPagination?.current || 0,
          };

          const groupOperationsReducedAsAnObject = {
            quantite: _.sumBy(group, (o: any) => +o.quantite),
            quantite_of: _.sumBy(group, (o: any) => +o.quantite_of),
            unite: this.operationComputedQuantity(firstOp).unite,
            unite_of: this.operationComputedQuantity(firstOp).unite_of,
          };

          return {
            key: listKey,
            [groupNameAttribute]: name,
            groupDisplayName,
            computedQuantity: this.operationComputedQuantity(
              {...groupOperationsReducedAsAnObject},
              true,
            )[this.selectedView === "day_view" ? "opQuantityTxt" : "txt"],
            data: groupedSortedOperations[key],
            has_split: groupedSortedOperations[key].some(
              ({is_split}) => is_split,
            ),
          };
        },
      );

      // FIXME: This is a temporary fix for the group sorting issue, it should
      // be removed once the issue is fixed by Mouheb (cf. OPL-7222)
      const sortedGroupedByKey = this.isManualSortingEnabled
        ? _.orderBy(
            groupedByKey,
            (group) =>
              _.min(group.data.map(({wip_order}) => wip_order ?? Infinity)),
            "desc",
          )
        : groupedByKey;

      const groupedOPsIDs = sortedGroupedByKey.map(
        ({data}) => data.map(({op_id}) => op_id),
        [],
      );
      this.$emit("operations-grouped", groupedOPsIDs);

      return sortedGroupedByKey;
    },
  },
  mounted() {
    // is used in EnCoursColumn
    this.$emit("operation-mounted");
  },
  methods: {
    getGroupColor(opList: SchedulingOperationCardsGroup) {
      const {groupNameAttribute, schedulingCurrentGroupBy, schedulingTags} =
        this;
      if (!schedulingCurrentGroupBy?.field) return;
      /**
       * we pass a payload using the key of the current grouping to retrieve the correct color
       * as defined in the getColorCategoryClass function
       */
      const colorCategoryClass = getColorCategoryClass(
        {
          [schedulingCurrentGroupBy.field]: opList[groupNameAttribute],
        },
        schedulingCurrentGroupBy,
        {schedulingTags},
      );
      if (!colorCategoryClass) return;
      return colorCategoryClass;
    },
    computeKey(key: GenericObject): string {
      if (!key) return "";
      const {maille} = this;
      const {name, secteurId, dateIso, isPast} = key;
      const date =
        maille === "month"
          ? moment(dateIso).startOf("week").format("YYYY-MM-DD")
          : dateIso;
      return [name, secteurId, date, isPast ? "past" : ""].join("_");
    },

    isSelected(opData: SchedulingOperation[]): boolean {
      if (!opData?.length) return false;
      return opData.every((op: any) => this.selectedOps[op.op_id]);
    },
    selectCard(operation: SchedulingOperation): void {
      this.$emit("select-card", operation);

      // moves the menu & listens to further keyboard events
      this.listenKeyEvents(true);
    },
    saveMoveOperation(): void {
      if (!this.canOperateOnOperationCards) return;
      this.$emit("save-move-operation");
    },
    moveOperation(op: SchedulingOperation): void {
      this.$emit("move-operation", op);
    },
    addGroupToSelectedList(opData: SchedulingOperation[]): void {
      if (!this.canOperateOnOperationCards) return;

      const filteredList = opData.filter(
        (op: SchedulingOperation) => this.selectedOps[op.op_id],
      );

      const arrayToEmit = filteredList.length ? filteredList : opData;

      arrayToEmit.forEach((op: SchedulingOperation): void => {
        this.$emit("select-card", op);
      });

      this.actionsMenuTopValue = 0;
      this.hasDebounced = true;
    },
    changeStatus(event: GenericObject): void {
      this.$emit("change-status", event);
    },
    changePriority(event: GenericObject): void {
      this.$emit("change-priority", event);
    },
    triggerKeyEvent(action: string): void {
      this.$emit("move-operation", {code: action});

      // moves the menu & listens to further keyboard events
      this.listenKeyEvents(true);
    },
    isDisplayedActionsMenu(opList: SchedulingOperationCardsGroup): boolean {
      const {selectedOpsIDs} = this;
      if (!selectedOpsIDs.length || !opList?.data?.length) return false;
      const firstItemItd = selectedOpsIDs[0];

      if (!firstItemItd) return false;

      return opList.data.some(
        (op: SchedulingOperation) => firstItemItd === op.op_id,
      );
    },
    setActionsMenuTopValue: _.debounce(function (this: any) {
      const entity = getFirstSelected();
      if (!entity) return;

      const isGroup = entity.classList.contains(
        CSS_OPERATIONS_CARDS_GROUP_SELECTED_CLASS,
      );
      const {offsetTop = 0} = entity;

      this.actionsMenuTopValue =
        offsetTop + (isGroup ? 0 : operationCardPaddingTopValue);

      this.hasDebounced = true;
    }, 150),
    listenKeyEvents(bool: boolean): void {
      const onKeyEvents = () => {
        /**
         * updates the position of the actions menu so that it is aligned with the
         * selected card (if the group itself is not selected)
         */
        this.hasDebounced = false;
        this.setActionsMenuTopValue();
      };

      if (bool) onKeyEvents();

      if (this.hasKeyEventsListenerInitialized === bool) return;

      this.hasKeyEventsListenerInitialized = bool;

      /**
       * the moving of operations can also be triggered directly from the keyboard
       * due to the snippet in CalendarRow resembling to the one below
       * this snippet's aim is to perform a recalculation of the menu position
       * upon moving operations using arrow keys
       */
      const documentFunction = bool
        ? "addEventListener"
        : "removeEventListener";

      document[documentFunction]("keyup", onKeyEvents);
      document[documentFunction]("keydown", onKeyEvents);
    },
    // returns the state of disability or enability for arrows of OperationsActionsMenu
    getComputedArrowsState(
      opList: SchedulingOperationCardsGroup,
      index: number,
    ) {
      if (this.isPast) {
        return {
          Left: false,
          Down: false,
          Up: false,
        };
      }

      const {Down, Up} = getArrowsState(this.selectedOpsIDs, opList.data);

      switch (index) {
        case 0:
        case this.operationsListGrouped.length - 1:
          return {[index ? "Down" : "Up"]: index ? Down : Up};
        default:
          return {};
      }
    },
  },
  unmounted() {
    this.listenKeyEvents(false);
  },
});
</script>
<style scoped lang="scss">
$padding: 4px;

.op-list-name {
  color: rgb(var(--v-theme-newPrimaryRegular));
  font-weight: 600;
  line-height: 20px;
}
.oplist-wrapper {
  padding: 8px;
  position: relative;

  &.day-view {
    padding: $padding;

    & .oplist-title {
      padding: $padding;

      &[class*="--colors-categories"] {
        padding-left: 12px;
      }
    }
  }

  &:not(:last-child) {
    padding-bottom: 0;

    & + :deep(.capacity-day-cell) {
      margin-top: $padding;
    }
  }
}
.min-width-max-content {
  min-width: max-content;
}
.has-split {
  background-color: rgb(var(--v-theme-newOrangeLight2));
}
</style>
<style lang="scss">
/* needs to be unscoped for the EnCours usage of grouped ops */
@import "@/scss/constants.scss";

.oplist {
  &-length {
    position: relative;
    z-index: 1;

    /**
    * the loop is going to 10, but above 7 digits (9.999.999 operations within a group)
    * the text goes beyond the box boundaries
    */
    @for $i from 1 to 10 {
      &.str-len-#{$i}::before {
        content: "";
        height: #{min($maxChipHeight, max($minChipRadius, $i + 1))}ch;
        width: #{max($minChipRadius, $i + 1)}ch;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background: rgb(var(--v-theme-newPrimaryRegular));
        border-radius: 50%;
        z-index: -1;
      }
    }
  }
  &-title {
    width: 100%;
    background-color: rgb(var(--v-theme-blanc));
    color: rgb(var(--v-theme-newLayerBackground));
    padding: $operationCardContentPadding;
    border: 1px solid rgb(var(--v-theme-newSelected));

    // should match CSS_OPERATIONS_CARDS_GROUP_SELECTED_CLASS
    &.selected-cards-group {
      border: 1px solid rgb(var(--v-theme-newPrimaryDark1)) !important;
      background-color: rgb(var(--v-theme-newPrimaryLight2));

      &.open {
        border-bottom-width: 0px;
      }
    }
  }
}
.absolute-spinner-pos {
  position: absolute;
  top: 0;
  left: -35px;
}
</style>
